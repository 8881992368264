import {
  cleanInterpretVar,
  getInterpretVar,
  isDefaultVarExpression,
  toJSON,
  transformObjToNewSingleObj,
} from "./index";
import { ProcessData, Task } from "../types/Task";
import React from "react";
import { KeyProps } from "../components/InboxComponent/InboxComponent";

export const getTaskInformation = async (
  task: { [index: string]: any },
  isExcelData = false,
  view?: "my_task" | "multi_task",
) => {
  let fieldValueArr: { label: string; value: string | number }[] = [];

  if (task.key_props && Array.isArray(JSON.parse(task.key_props))) {
    if (JSON.parse(task.key_props).length > 0) {
      let keyProps = JSON.parse(task.key_props) as KeyProps[];
      const obj = transformObjToNewSingleObj(task.process_data, "value");

      if (Array.isArray(keyProps)){
        keyProps = keyProps.filter(prop => prop.show_on && Array.isArray(prop.show_on) && prop.show_on.length > 0 && view ? prop.show_on?.some(show => show === view) : true)

        for await (const props of keyProps) {

          let value = getInterpretVar({
            item: obj,
            strVar: props.value,
            inDeep: false,
          });
          if (isDefaultVarExpression(value)) {
            value = cleanInterpretVar({
              item: obj,
              strVar: value,
              inDeep: false,
            });
          }

          const label =
              props.type === "reference" && props.field_to_show
                  ? `${props.model}_${props.label}`
                  : props.type === "reference" && !props.field_to_show
                      ? ""
                      : props.label;

          if (isDefaultVarExpression(props.value)) {
            if (props.label.toLowerCase().includes("client")) {
              task.client_info = value;
            } else {
              if (label) {
                if (props.new_column) {
                  task[label] = isDefaultVarExpression(value) ? "" : value;
                } else {
                  fieldValueArr.push({
                    label: label,
                    value,
                  });
                }
              }
            }
          } else {
            if (label) {
              if (
                  (
                      task?.process_data[
                          props.value as keyof ProcessData
                          ] as ProcessData
                  )?.value
              ) {
                const value = (
                    task.process_data[
                        props.value as keyof ProcessData
                        ] as ProcessData
                ).value;
                if (props.new_column) {
                  task[label] = isDefaultVarExpression(value) ? "" : value;
                } else {
                  fieldValueArr.push({
                    label: label,
                    value: isDefaultVarExpression(value) ? "" : value,
                  });
                }
              } else {
                // Clear the column is exist to avoid show default var like ${my var} on the column
                if (props.new_column) {
                  task[label] = "";
                }
              }
            }
          }
        }
      }



      fieldValueArr = fieldValueArr.filter((field) => field.value);
      task.information_metadata = fieldValueArr;
      task.information = isExcelData ? (
        fieldValueArr
      ) : (
        <div className="d-flex flex-column">
          {fieldValueArr.map((field, index) => (
            <span key={field.label + "_" + index}>
              {field.label}: {field.value}
            </span>
          ))}
        </div>
      );
    } else {
      task.information = "N/A";
    }
  }

  return task;
};

export const mapTaskMetaInformation = (
  task: Task[],
  queries: {
    [key: string]: { [key: string]: string[] | any[] };
  },
) => {
  const newTaskList = [...task];

  return newTaskList.map((task) => {
    if (task.key_props && Array.isArray(JSON.parse(task.key_props))) {
      if (JSON.parse(task.key_props).length > 0) {
        const keyProps = JSON.parse(task.key_props) as KeyProps[];

        if (task.information_metadata) {
          task.information_metadata = task.information_metadata.map((info) => {

            const prop = keyProps.find(
              (prop) =>
                prop.type === "reference" &&
                info.label === `${prop.model}_${prop.label}`,
            );
            if (prop && queries[prop.model].results) {
              const value = queries[prop.model].results.find(
                (result) => result[prop.field] === info.value,
              );
              if (value && value[prop.field_to_show]) {
                info.label = prop.label;
                info.value = value[prop.field_to_show];
              }
            }
            return info;
          });

          Object.keys(task).forEach((key) => {
            const prop = keyProps.find(
              (prop) =>
                prop.type === "reference" &&
                key === `${prop.model}_${prop.label}`,
            );

            if (
              prop &&
              queries[prop.model].results &&
              typeof task[key as keyof typeof task] === "string"
            ) {
              const value = queries[prop.model].results.find(
                (result) =>
                  result[prop.field] === task[key as keyof typeof task],
              );

              if (value && value[prop.field_to_show]) {
                task = {
                  ...task,
                  [prop.label]: value[prop.field_to_show],
                };
              }
            }
          });

          task.information = (
            <div className="d-flex flex-column">
              {task.information_metadata.map((field, index) => (
                <span key={field.label + "_" + index}>
                  {field.label}: {field.value}
                </span>
              ))}
            </div>
          );
        }
      }
    }

    return task;
  });
};

export const parseStringToJson = (text: string) => {
  // Find the index of '{' and '}'
  let startIndex = text.indexOf("{");
  let endIndex = text.lastIndexOf("}");

  // Extract the substring between '{' and '}'
  let result = text.substring(startIndex!, endIndex! + 1);

  if (!result.length) {
    result = "{}";
  }

  return toJSON(result) || {};
};
