import React, { useMemo, useState } from "react";
import { useRouter } from "next/router";
import BulkUpdateTableComponent from "../../../../../components/EmailTemplateComponents/BulkUpdateTableComponent";
import { EmailContext } from "../../../../../components/EmailTemplateComponents";
import { Column } from "../../../../../components/Shared/CustomTableComponent/CustomTableComponent";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/Reducers";
import { toast } from "../../../../../utils";
import { BulkUpdateData } from "../../../../../services/backend/SalesService";
import {sendSbxEvent} from "../../../../../utils/analyticsUtils";
import useTranslate from "../../../../../hooks/useTranslate";

type Obj = { [key: string]: string | number | null | undefined };
type ColumObj = { [key: string]: any };

export interface BulkUpdate {
  cs: string;
  title: string;
  columns: ColumObj;
  afterKey?: string;
  beforeKey?: string;
  type?: "migrate_users" | "migrate_memberships";
  name?: string;
  label?: string;
}

export type Props = {
  modelNameProps?: string
  csProps?: string
  bulkUpdatesProps?: BulkUpdate[]
}

const DataBulkUpdate = ({bulkUpdatesProps = [], csProps = "", modelNameProps = ""}: Props) => {
  const router = useRouter();
  const { modelName = "", cs = "" } = router.query;
  const { bulk_updates = [] } = useSelector(
    (state: RootState) => state.AuthReducer?.user?.config?.sbx_crm!,
  );
  const {t} = useTranslate('common');
  const [columnsLocal, setColumnsLocal] = useState<Column[]>([]);
  const [allColumns, setAllColumns] = useState<Column[]>([]);
  const [data, setData] = useState<Obj[]>([]);
  const [loading, setLoading] = useState(false);
  const [oldData, setOldData] = useState<{
    data?: string[];
    columns?: string[];
  }>({});

  const [cloudscript, setCloudscript] = useState("")


  React.useEffect(() => {

    if (csProps){
      setCloudscript(csProps)
    }else{
      if (cs){
        setCloudscript(cs as string)
      }
    }

  }, [cs, csProps]);


  const bulk = useMemo(() => {
    if (bulkUpdatesProps && bulkUpdatesProps.length > 0 ){
      return bulkUpdatesProps.find((bulk) => bulk.cs === cloudscript || bulk.name === cloudscript);
    }else{
      return bulk_updates.find((bulk) => bulk.cs === cloudscript || bulk.name === cloudscript);
    }
  }, [cloudscript, bulk_updates, bulkUpdatesProps]);

  const uploadData = async () => {
    try {
      setLoading(true);
      if (bulk) {
        let queryParams: { [key: string]: string } = {};

        switch (bulk.type) {
          case "migrate_users":
            if (bulk.beforeKey) queryParams["beforeKey"] = bulk.beforeKey;

            if (bulk.afterKey) queryParams["afterKey"] = bulk.afterKey;
            break;
        }

        if (data.length && typeof cloudscript !== "object") {
          const resUpdates = await BulkUpdateData({
            key: cloudscript,
            type: bulk.type ?? "cs",
            params: { data },
            queryParams,
          });
          if (resUpdates?.success) {
            sendSbxEvent({
              name: "sbx_crm_bulk_update",
              props: {
                key: cloudscript,
                type: bulk.type ?? "cs",
                params: { data },
                query_params: queryParams
              },
            })
            toast({ type: "success", message: t("upload_success") });
          } else {
            toast({ type: "error", message: t("upload_error") });
          }
        } else {
          toast({ type: "error", message: "Require data" });
        }
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <>
      {!modelNameProps && <h1> {modelName}</h1>}
      <EmailContext.Provider
        value={{
          setColumns: setColumnsLocal,
          columns: columnsLocal,
          data,
          setData,
          oldData,
          allColumns,
          setAllColumns,
          setOldData,
        }}
      >
        <BulkUpdateTableComponent
          name={modelName as string}
          bulk={bulk}
          loading={loading}
          uploadData={uploadData}
        />
      </EmailContext.Provider>
    </>
  );
};

export default DataBulkUpdate;
