import React, { useContext, useEffect, useMemo, useState } from "react";
// core components
// @ts-ignore
import SimpleHeader from "components/Headers/SimpleHeader.js";
import CustomTableComponent, { Column } from "../Shared/CustomTableComponent/CustomTableComponent";
import useTranslate from "../../hooks/useTranslate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudUpload,
  faFileExcel,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import {
  convertTableRowsToCSVString,
  downloadTextToFile,
  handleFileUploadV2,
  toMap,
} from "../../utils";
import { EmailContext } from "./index";
import { State } from "../../types/State";
import { SbxCrmDataColumn } from "../../types/User";
import { BulkUpdate } from "../../pages/bulk/[bulkName]/[modelName]/[cs]";

interface Iprops {
  uploadData?: () => void;
  loading: boolean;
  bulk?: BulkUpdate;
  name?: string;
}

const BulkUpdateTableComponent = ({
  uploadData,
  loading: uploadingData,
  name,
  bulk,
}: Iprops) => {
  const { data, setData, loading } = useContext(EmailContext);

  const { t } = useTranslate("email-blast");

  const columns = useMemo(() => {
    const columns = bulk?.columns;
    if (columns) {
      return Object.entries(columns).map(([key, value]) => {
        return {
          name: value,
          header: key,
          data: { label: key, column: value },
        } as Column;
      });
    }

    return [];
  }, [bulk]);

  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    if (file) {
      handleFileUploadV2(file, (arr) => {
        if (arr?.length) {
          const _map = toMap(columns, "header");
          const headers = Object.keys(arr[0])
            .map((key) => _map[key.trim()]?.data)
            .filter((column) => column) as SbxCrmDataColumn[];


          const _rows = arr.map((row) => {
            //apply rules from column setup
            return headers.reduce((obj: any, column) => {
              //validate column value in row
              obj = { ...obj, [column.column]: row[column.label] };

              return obj;
            }, {});
          });

          setData(_rows);
        }
      });
    }
  }, [file, columns]);

  return (
    <>
      <div className="border-0 p-4">
        <div className="mb-2 d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <div>
              <label className="btn btn-light btn-sm" htmlFor="file-upload">
                {t("select-file")}
              </label>
              <b className="ms-2">{file?.name}</b>
              <input
                id="file-upload"
                className="d-none"
                type="file"
                accept=".csv"
                onChange={(e) => setFile(e.target.files?.[0] || null)}
              />
            </div>
            <button
              disabled={uploadingData || !file}
              onClick={uploadData}
              className="btn btn-primary btn-sm"
            >
              <FontAwesomeIcon
                icon={uploadingData ? faSpinner : faCloudUpload}
                spin={uploadingData}
                className="me-2"
              />
              {t(`common:upload_file`)}
            </button>
          </div>

          <div>
            <button
              className="btn btn-success btn-sm"
              onClick={() => {
                downloadTextToFile(
                  convertTableRowsToCSVString(columns, []),
                  "csv",
                  name ?? "bulk",
                );
              }}
            >
              <FontAwesomeIcon icon={faFileExcel} /> {t("Export Template")} CSV
            </button>
          </div>
        </div>
        <CustomTableComponent
          loading={loading === State.PENDING}
          columns={columns}
          data={data}
        />
      </div>
    </>
  );
};

export default BulkUpdateTableComponent;
