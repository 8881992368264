import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {Control, Controller} from "react-hook-form";
import DateRangeComponent from "../../Shared/FieldComponents/DateRangeComponent";
import RenderMonthElement from "../../Shared/RenderMonthElement";
import {Field} from "../../../types/Field";
import useDeviceDetect from "../../../hooks/useDeviceDetect";
import {FieldType} from "../../../types/FieldType";
import DateComponent from "../../Shared/FieldComponents/DateComponent";
import {CrmInputContext} from "./CrmRenderInput";
import {TaskComponentContext} from "../../../context/TaskContext";
import moment, {Moment} from 'moment';


type Props = {
    field: Field,
    control?: Control<any, object>
};

const CrmDateInputComponent = ({field, control}: Props) => {

    const {isMobile} = useDeviceDetect();
    const [defaultValue, setDefaultValue] = useState<string>('');
    const {getDefaultValue} = useContext(CrmInputContext)
    const {formState} = useContext(TaskComponentContext);

    useEffect(() => {
        setTimeout(() => {
            setDefaultValue(getDefaultValue(field) || "");
        }, 1000)
    }, [formState]);

    const convertAndValidateDate = (dateNumber: string): Moment | null => {
        // Convierte el número a una cadena para procesarla
        const dateString = dateNumber.toString();

        // Valida que tenga exactamente 8 dígitos (formato YYYYMMDD)
        if (dateString.length !== 8) {
            return null;
        }

        // Usa moment para intentar crear una fecha válida
        const date = moment(dateString, 'YYYYMMDD', true); // 'true' fuerza la validación estricta del formato

        // Valida si la fecha es válida en el calendario (ejemplo: no existen días 43, meses 13, etc.)
        if (!date.isValid()) {
            return null;
        }

        // Si la fecha es válida, retorna el objeto Moment
        return date;
    };


    function formatValues(field: Field, value: any, structure?: boolean) {
        switch (field.field_type) {
            case FieldType.DATE_RANGE:
                if (structure) {
                    return `${value.startDate?.toISOString() ?? ''},${value.endDate?.toISOString() ?? ''}`;
                } else {
                    const [s, e] = (value ?? ',').split(',');
                    return {startDate: s ? new Date(s) : null, endDate: e ? new Date(e) : null};
                }
            case FieldType.DATE:
                if (structure) {
                    return value?.toISOString() ?? null;
                } else {
                    return value ? new Date(value) : null;
                }
            default:
                return value;
        }
    }

    // Helper function to handle now/tomorrow
    const getSpecialDate = (dateStr: string | undefined): Moment | null => {
        if (!dateStr) return null;
        if (dateStr === "${now}") return moment();
        if (dateStr === "${tomorrow}") return moment().add(1, 'days');
        const convertedDate = convertAndValidateDate(dateStr);
        return convertedDate ? moment(convertedDate) : null;
    };

    const getDateField = () => {
        const input: { [key: string]: JSX.Element } = {
            [FieldType.DATE_RANGE]: <Controller
                control={control}
                name={field.name}
                defaultValue={defaultValue}
                rules={{
                    required: field.required && !field.read_only
                }}
                render={({field: {onChange, value}}) => (
                    <DateRangeComponent
                        id={field.id.toString()}
                        value={formatValues(field, value)}
                        onChange={e => onChange(formatValues(field, e, true))}
                        disabled={field.read_only}
                        required={field.required}
                        renderMonthElement={RenderMonthElement}
                        isOutsideRange={() => false}
                        orientation={isMobile ? 'vertical' : 'horizontal'}
                    />
                )}
            />,
            [FieldType.DATE]: <Controller
                control={control}
                name={field.name}
                defaultValue={getDefaultValue(field) ?? ''}
                rules={{
                    required: field.required && !field.read_only
                }}
                render={({field: {onChange, value}}) => (
                    <DateComponent
                        id={field.id.toString()}
                        value={formatValues(field, value)}
                        onChange={e => onChange(formatValues(field, e, true))}
                        disabled={field.read_only}
                        required={field.required}
                        renderMonthElement={RenderMonthElement}
                        orientation={isMobile ? 'vertical' : 'horizontal'}
                        isDayBlockedDate={field?.format_rules_definition?.blocked_from_date}
                        isOutsideRange={(date1) => {
                            const { blocked_date } = field?.format_rules_definition || {};

                            if (!blocked_date) return false;

                            // Precompute the before and after dates
                            const before_date = getSpecialDate(blocked_date.before_date);
                            const after_date = getSpecialDate(blocked_date.after_date);

                            // Validate before_date
                            if (before_date && date1?.isBefore(before_date, 'day')) {
                                return true;
                            }

                            // Validate after_date
                            return !!(after_date && date1?.isAfter(after_date, 'day'));


                        }}
                    />
                )}
            />
        }

        return input[field.field_type] ?? null
    }

    return getDateField();
};

export default CrmDateInputComponent