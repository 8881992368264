import ModalComponent from "./ModalComponent/ModalComponent";
import {useState} from "react";
import useAsyncEffect from "../../../hooks/useAsyncEffect";
import {getTemplates} from "../../../services/backend/EmailService";
import Select, {OptionTypeBase} from "react-select";
import {Template} from "../../../types/Template";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCloudDownloadAlt, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Response} from "../../../types/Response";
import useTranslate from "../../../hooks/useTranslate";

interface Props {
  open: boolean;
  toggle: (data: boolean) => void;
  onSelected: (template: Template) => void;
}

function SelectTemplateModal(props: Props) {
  return props.open ? <Modal {...props}/> : null;
}

const Modal = ({open, toggle, onSelected}: Props) => {
  const [templates, setTemplates] = useState<Template[]>([]);
  const [template, setTemplate] = useState<null | OptionTypeBase>(null);
  const [loading, setLoading] = useState(false);
  const {t} = useTranslate("email-blast")

  const loadTemplates = async () => {
    setLoading(true);
    const resTemplate: Response = await getTemplates();
    if (resTemplate.success) {
      setTemplates(resTemplate?.items || []);
    }
    setLoading(false);
  }

  useAsyncEffect(async () => {
    await loadTemplates();
  }, []);

  return (
    <ModalComponent
      title={<><FontAwesomeIcon icon={faCloudDownloadAlt}/> {" " + t("select-template")}</>}
      size="sm"
      footer={<>
        <button
          className="btn btn-primary btn-sm"
          disabled={!template}
          onClick={() => onSelected(template?.value)}>
          <FontAwesomeIcon icon={faCheck}/>{" " + t("common:load")}
        </button>
        <button
          className="btn btn-light btn-sm ms-1"
          onClick={() => toggle(false)}>
          <FontAwesomeIcon icon={faTimes}/>{" " + t("common:cancel")}
        </button>
      </>}
      isOpen={open} toggle={() => toggle(false)}>

      <label>{t("templates")}</label>
      <Select isLoading={loading}
              isDisabled={loading}
              value={template}
              options={templates.map(t => ({label: t.name, value: t}))}
              onChange={setTemplate}/>

    </ModalComponent>
  )
}


export default SelectTemplateModal;
