import React, {useContext, useEffect, useRef, useState} from "react";
import {Button, Container, Input, ListGroupItem, Progress} from "reactstrap";
import BodyEmailInputs from "./BodyEmailInputs";
import {emailDataToSend, getTemplate, sendBulkEmailService, transformData} from "../../services/backend/EmailService";
import {capitalize, getHtmlFromEditor, replaceValues, toast} from "../../utils";
import CustomTableComponent, {Action} from "../Shared/CustomTableComponent/CustomTableComponent";
import useTranslate from "../../hooks/useTranslate";
import ModalComponent from "../Shared/Modal/ModalComponent/ModalComponent";
import SpinnerComponent from "../Shared/SpinnerComponent";
import {EmailContext} from "./index";
import {State} from "../../types/State";
import {AnyData} from "../../types/AnyData";
import {Query} from "../Shared/QueryComponent/QueryComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog, faPaperPlane, faRotate, faSyncAlt, faTimes, faWarning} from "@fortawesome/free-solid-svg-icons";
import useStatusNetwork from "../../hooks/useStatusNetwork";
import {useDispatch, useSelector} from "react-redux";
import {actionsModal, ModalTypes} from "../../store/Modal/Slice";
import {RootState} from "../../store/Reducers";
import EmailSentComponent from "./EmailSentComponent";

export const sizeToMaxPage = 1000;
const defaultValuesToOldState = {
  columns: {}
};

const EndProcessComponent = () => {

  const {
    columns,
    allColumns,
    emailEditorRef: refEditor,
    data,
    page,
    totalData,
    loading,
    onFinish,
    query,
    setLoading,
  } = useContext(EmailContext);

  const {user: {config}} = useSelector((root: RootState) => root.AuthReducer);
  const emailTest = config?.sbx_crm?.extra_setup?.communications?.email_test ?? "copy@socobox.co";
  const [htmlViewer, setHtml] = useState();
  const dispatch = useDispatch();
  const [progressArr, setProgress] = useState<{ item: any, res: any }[]>([]);
  const [loadingTemplate, setLoadingTemplate] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [openTableSetup, setOpenSetup] = useState(false);
  const [columnState, setColumnState] = useState<{ columns: AnyData }>(defaultValuesToOldState);
  const {status} = useStatusNetwork();
  const isTest = useRef(false);
  const progress = progressArr.length + 1;

  useEffect(() => {
    if (!status) {
      toast({message: "Lost connection!", type: "warn"});
    } else if (status && loading === State.NETWORK_FAILED) {
      toast({message: "Established connection!", type: "info"});
      openRemaining();
    }
  }, [status]);

  function openRemaining() {
    dispatch(actionsModal.openModal({
      type: ModalTypes.DYNAMIC_COMPONENT_MODAL,
      title: "Mails sent",
      closeAfterSuccess: true,
      state: State.PENDING,
      labelSuccess: "Continue",
      onSuccess: () => sendEMails(true, isTest.current),
      component: (
        <div key="table-pending">
          <span><FontAwesomeIcon className="text-warning" icon={faWarning}/> To continue sending, press continue button!</span>
          <hr/>
          <CustomTableComponent
            key={"table 3"}
            useLocalPage
            columns={[{header: "Email", name: bodyEmail?.field ?? "email"}]}
            data={progressArr.map(d => d.item)}/>
        </div>
      )
    }))
  }

  const toggleModal = () => setOpen(!isOpen);
  const toggleModalSetup = () => setOpenSetup(!openTableSetup);

  const [bodyEmail, setBodyEmail] = useState<{
    bcc: string[],
    cc: string[],
    subject: string,
    from: string,
    field: string
  } | null>(null);

  const {t} = useTranslate("email-blast");

  const loadData = async (row: any) => {
    setLoadingTemplate(true);
    toggleModal();
    const htmlR = await getHtmlFromEditor(refEditor);
    const {template, data} = transformData(replaceValues(htmlR), row, columnState.columns);
    const {item, success} = await getTemplate({data, template});
    if (success) {
      setHtml(item);
      setLoadingTemplate(false);
    }
  }

  function onchangeOldState(key: "columns", keyValue: string) {
    setColumnState({
      ...columnState,
      columns: {
        ...columnState.columns,
        [keyValue]: !columnState.columns[keyValue]
      }
    });
  }

  function bulkDisrupted() {
    setLoading && setLoading(State.NETWORK_FAILED);
  }

  async function defaultFunc(body: AnyData, p: number, query: Query, html: string, cb: () => void, sent: any[], config: {
    test?: { email: string };
  }) {
    const res = await emailDataToSend(columns, p, query, {size: sizeToMaxPage});
    if (res.success) {
      return await sendBulkEmailService(body,
        res.items,
        html,
        columnState.columns,
        (e, res) => setProgress(prevState => [...prevState, res]),
        cb,
        sent, config, {page: p});
    }
    return [];
  }

  const show = () => {
    if (bodyEmail) {
      dispatch(actionsModal.openModal({
        type: ModalTypes.DYNAMIC_COMPONENT_MODAL,
        title: "Email sent",
        size: "lg",
        toggle: () => {
          dispatch(actionsModal.closeModal({type: ModalTypes.DYNAMIC_COMPONENT_MODAL}));
          setProgress([]);
        },
        noFooter: true,
        component: <EmailSentComponent field={bodyEmail.field} data={progressArr}/>
      }));
    }
  }


  useEffect(() => {
    if (loading === State.FINISHED && progressArr.length) {
      show();
    }
  }, [loading, progressArr])

  async function sendEMails(retry?: boolean, test?: boolean) {
    isTest.current = !!test;
    const setup = {test: test ? {email: emailTest} : undefined};
    let resultHtml = await getHtmlFromEditor(refEditor);
    if (progressArr.length && !retry) setProgress([]);
    setLoading && setLoading(State.SENDING);
    const html = replaceValues(resultHtml);
    let failed = false;
    if (bodyEmail?.subject && bodyEmail.field) {
      if ((!query || !totalData) && data.length) {
        console.log("info => sending emails from local data!");
        await sendBulkEmailService(bodyEmail, data, html, columnState.columns, (e, res) => setProgress(prevState => [...prevState, res]), () => {
          failed = true;
          bulkDisrupted();
        }, retry ? progressArr : [], setup);
      } else if (totalData && query) {
        let page = 0, action;
        if (totalData > sizeToMaxPage) {
          action = "pagination"
        } else {
          action = "all";
        }
        switch (action) {
          case "pagination":
            console.log("info => sending emails from pagination data!");
            const count = (totalData) / sizeToMaxPage;
            while (page <= count) {
              page++;
              await defaultFunc(bodyEmail, page, query, html, () => {
                page = count;
                failed = true;
                bulkDisrupted();
              }, retry ? progressArr : [], setup);
            }
            break;

          default:
            console.log("info => sending emails from all data!");
            await defaultFunc(bodyEmail, page, query, html, () => {
              failed = true;
              bulkDisrupted();
            }, retry ? progressArr : [], setup);
            break;
        }
      }

      if (!failed) {
        setTimeout(() => {
          setLoading && setLoading(State.FINISHED);
          toast({type: 'success', message: t("process_finished")});
        }, 2000)
      }
    } else {
      setLoading && setLoading(State.RESOLVED);
      toast({type: 'warn', message: t("warn-email-form")});
    }
  }


  const actions: Action[] = [
    {label: <i className="fa fa-eye "/>, onAction: loadData, type: "primary", title: t("common:detail")}
  ]
  const count = totalData ?? data.length;

  const toReset = Object.values(columnState.columns).some(e => e);
  return (
    <Container fluid>
      {loading === State.SENDING ?
        <Progress
          style={{minHeight: "15px"}}
          color="success"
          value={progress / (totalData ?? data.length) * 100}
          animated>{progress <= count ? progress : count} of {count}
        </Progress> : (
          <div className="d-flex justify-content-end">
            <Button
              color="primary"
              size="sm"
              onClick={() => sendEMails()}
              className="my-2"> <FontAwesomeIcon icon={faPaperPlane}/> {t("send-emails")}
            </Button>
            <Button
              color="warning"
              size="sm"
              onClick={() => sendEMails(false, true)}
              className=" ms-1 my-2"> <FontAwesomeIcon icon={faPaperPlane}/> {t("common:test")}
            </Button>
            {loading === State.NETWORK_FAILED && status && <Button
              color="warning"
              size="sm"
              onClick={openRemaining}
              className="mx-2 my-2"><FontAwesomeIcon icon={faRotate}/> {t("retry")}
            </Button>}
          </div>
        )}

      <BodyEmailInputs
        columns={columns}
        onChange={e => setBodyEmail({...bodyEmail, ...e})}/>

      <div>
        <CustomTableComponent
          key={'table 2'}
          loading={loading === State.PENDING}
          onChangePage={p => onFinish && page !== false && onFinish(columns, allColumns, p)}
          currentPage={typeof page === "number" ? page : undefined}
          columnsSetting
          columns={columns.map(c => {
            if (c.type === "ArrayObject") {
              c.action = (
                <Button title={t("Configure columns")}
                        onClick={toggleModalSetup} color="primary" size="sm">
                  <FontAwesomeIcon
                    icon={faCog}/>
                </Button>
              );
            }
            return c;
          })}
          actions={actions}
          data={data}
          totalData={totalData}/>
      </div>

      {openTableSetup && <ModalComponent
        title={t("Configure columns")}
        isOpen={openTableSetup}
        footer={(
          <Button color="light" size="sm" onClick={toggleModalSetup}>
            <FontAwesomeIcon icon={faTimes}/> {t("common:close")}
          </Button>
        )}
        toggle={toggleModalSetup}>
        {
          (data[0]?.all_data ? data[0]?.all_data[0] : false) ? (
            <div>
              {toReset && <div className="py-2 d-flex justify-content-end">
                <Button color="link"
                        size="sm"
                        onClick={() => {
                          setColumnState(defaultValuesToOldState);
                        }}>
                  <FontAwesomeIcon icon={faSyncAlt}/> {t("common:clear")}
                </Button>
              </div>}
              {Object.keys(data[0].all_data[0])
                .sort((a, b) => a.localeCompare(b)).map(key => {
                  return (
                    <ListGroupItem key={key} className="my-1">
                      <label className="pointer" htmlFor={key + "setup"}>
                        <Input
                          checked={!!columnState.columns[key]} id={key + "setup"}
                          onChange={() => onchangeOldState("columns", key)}
                          type="checkbox"
                          name={key}
                          className="mr-1"/>
                        {capitalize(key.split("_").join(" "))}
                      </label>
                    </ListGroupItem>
                  )
                })}
            </div>
          ) : (
            <div>
              {t("no_results")}
            </div>
          )
        }
      </ModalComponent>}

      <ModalComponent
        size="xl"
        title={t("preview")}
        isOpen={isOpen}
        footer={(
          <div className="text-right">
            <button
              onClick={toggleModal}
              className="btn btn-light btn-sm">
              {t("common:close")}
            </button>
          </div>
        )}
        toggle={toggleModal}>
        {loadingTemplate ? (
          <div className="d-flex justify-content-center">
            <SpinnerComponent/>
          </div>
        ) : (
          <div dangerouslySetInnerHTML={{__html: htmlViewer || ""}}/>
        )}
      </ModalComponent>
    </Container>
  )
}

export default EndProcessComponent;
