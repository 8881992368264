import React, {useReducer, useState} from 'react';
import {useRouter} from 'next/router';

import {useDispatch, useSelector} from 'react-redux';
import {Button} from "reactstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faEye, faEyeSlash, faFilter, faSpinner, faSyncAlt} from '@fortawesome/free-solid-svg-icons';
import {Tooltip} from "antd";
import {
    Alias,
    AnalyticQuery,
    AnalyticQueryAction,
    BaseAnalyticQuery,
    ModelsAndFields,
    Report,
    SourceFilter,
    TruncateReport
} from "../../types/Analytic";
import {Column} from "../Shared/CustomTableComponent/CustomTableComponent";
import {State} from "../../types/State";
import {Permissions} from "../../types/Permissions";
import useTranslate from "../../hooks/useTranslate";
import {authReducer} from "../../store/Selectors";
import {Condition, SbxResponse} from "../../types/Sbx";
import {findByModel} from "../../services/backend/SbxService";
import {FilterTableReport} from "../Shared/FilterTableDataComponent/FilterTableDataComponent";
import {getReportQuery, IsJsonString, removeDuplicateFromArrayDeepObj, ResponseReportQuery, toast} from "../../utils";
import {routerActions} from "../../store/RouterReducer";
import useAsyncEffect from "../../hooks/useAsyncEffect";
import {getLastReportUpdate} from "../../services/backend/AnalyticsService";
import ButtonComponent from "../Shared/ButtonComponent";
import Permission from "../AuthorityPermission/Permission";
import MultiReportsComponent from "../ReportsComponent/MultiReportsComponent";
import ReportComponent from "../ReportsComponent/ReportComponent";
import ReportFiltersByQueryComponent from "../ReportsComponent/ReportFiltersByQueryComponent";
import ReportAsyncMlComponent from "./ReportAsyncMLComponent";
import ReportChatBotComponent from "./ReportChatBotComponent";

enum Types {
    SET_STATE,
    SET_MULTI_STATE
}

export interface MultiReport extends Report {
    columns: Column[],
    data: any[],
    docKeys?: { [key: string]: any }
    allColumns: Column[],
    allColumnsCopy?: Column[],
    info: { [key: string]: any }
    filters: { table_name: string, label: string }[]
}

interface InitialReducerState {
    isLoading: State;
    report_in_progress: boolean
    data: any[];
    copyData: any[];
    columns: Column[];
    copyColumns: Column[];
    hideColumns: Column[];
    allColumns: Column[];
    remove_empty_columns: boolean;
    report?: Report
    query?: BaseAnalyticQuery
    nColumns: number;
    multiReports: MultiReport[];
    isGroupReport: boolean;
    filters: { table_name: string, label: string }[];
    filtersByParent: boolean;
    isAsyncMlQuery?: boolean
    predictInfo?: { [key: string]: any }
}

const initialState: InitialReducerState = {
    isLoading: State.IDLE,
    data: [],
    report_in_progress: false,
    copyData: [],
    columns: [],
    copyColumns: [],
    allColumns: [],
    hideColumns: [],
    filters: [],
    multiReports: [],
    nColumns: 0,
    isGroupReport: false,
    filtersByParent: false,
    remove_empty_columns: false,
    isAsyncMlQuery: false,
    predictInfo: {}
};


function reducer(state: InitialReducerState, {
    type,
    payload
}: { type: Types, payload: { name: string, value?: any } | { name: string, value: any }[] }) {
    switch (type) {
        case Types.SET_STATE:
            return {
                ...state,
                [(payload as { name: string, value: any }).name]: (payload as { name: string, value: any }).value
            };
        case Types.SET_MULTI_STATE:
            (payload as { name: keyof InitialReducerState, value: any }[]).forEach(data => {
                state = {...state, [data.name]: data.value};
            });
            return {...state};
        default:
            throw new Error();
    }
}

type Props = {
    reportKeyProp?: string
    baseActions?: AnalyticQueryAction[]
    baseFilters?: SourceFilter[]
    isDashboardView?: boolean
}

const permission = Permissions.REPORT_EDIT;

const validateTruncateReload = (report: Report) => {
    let reload = true
    if (report?.truncate !== null) {
        if (IsJsonString(report?.query as string)) {
            const query = JSON.parse(report?.query as string) as BaseAnalyticQuery

            if (IsJsonString(report?.truncate as string)) {
                const truncate = JSON.parse(report?.truncate as string) as TruncateReport
                reload = !!truncate?.cache
            } else {
                if (!query.truncate || query.truncate === "" || query.truncate === "{}") {
                    const truncate = report?.truncate as string
                    reload = !truncate || truncate === "" || truncate === "{}";
                }
            }
        }
    }


    return reload
}
const ReportDetail = ({reportKeyProp, baseActions, isDashboardView, baseFilters}: Props) => {
    const history = useRouter();
    const {reportKey, from} = history.query;
    const {t} = useTranslate('common');
    const [filters, setFilters] = useState<AnalyticQueryAction[]>([])
    const [openFilters, setOpenFilters] = useState(false)
    const toggleFilters = () => setOpenFilters(prevState => !prevState)
    const dispatch = useDispatch();
    const [reportName, setReportName] = useState("");
    const [stateLocal, dispatchLocal] = useReducer(reducer, initialState);
    const [showConfig, setShowConfig] = useState(false)
    const [tab, setTab] = useState('0')
    const [time, setTime] = useState("")
    const [trainingStatus, setTrainingStatus] = useState("")
    const {user} = useSelector(authReducer)
    const [truncate, setTruncate] = useState<TruncateReport | null>(null)
    // const [filesDataRef, setFilesDataRef] = useState<{ [column_row: string]: (Content[]) }>({})


    // React.useEffect(() => {
    //     console.log('filesDataRef', filesDataRef.current)
    // }, [filesDataRef.current]);


    const dispatchForm = ({name, value}: { name: keyof InitialReducerState, value: any }) => {
        dispatchLocal({type: Types.SET_STATE, payload: {value, name}});
    };

    const dispatchMultiForm = (forms: { name: keyof InitialReducerState, value: any }[]) => {
        dispatchLocal({type: Types.SET_MULTI_STATE, payload: forms});
    };

    const launchQuery = async (report: Report, reload = false, noCache?: boolean) => {

        dispatchForm({name: 'isLoading', value: State.PENDING});

        if (baseActions && !baseFilters) {
            const query: AnalyticQuery = JSON.parse(report.query)
            query.actions = query.actions.concat(baseActions)
            report.query = JSON.stringify(query)
        }

        if (baseFilters && baseFilters.length > 0) {
            const query: AnalyticQuery = JSON.parse(report.query)
            query.filters = baseFilters
            report.query = JSON.stringify(query)
        }


        if (report?.group) {
            let filtersByParent = false;
            const where: Condition[] = [{
                'ANDOR': 'AND',
                'GROUP': [{'ANDOR': 'AND', 'VAL': reportKey as string, 'FIELD': 'parent_group', 'OP': '='}]
            }];

            const responseQueries: SbxResponse<Report> = await findByModel({
                row_model: 'sbx_crm_report',
                where
            });
            let filters: FilterTableReport[] = [];

            if (report.filter && JSON.parse(report.filter)?.length > 0) {
                filters = JSON.parse(report.filter);
                filtersByParent = true;
            }


            if (responseQueries?.success && responseQueries.results) {

                const queries = [];

                for (const responseQuery of responseQueries.results) {
                    const report = await getReportQuery({report: responseQuery, parentFilters: filters, user, reload});

                    if (report && report.data) {
                        queries.push({
                            ...report,
                            ...responseQuery,
                            report_in_progress: responseQuery.report_in_process ?? false
                        });
                    }
                }

                // report.isDrillDownQuery ? stateLocal.query : IsJsonString(report.query) ? JSON.parse(report.query) : undefined

                dispatchMultiForm([
                    {name: 'multiReports', value: queries.sort((a, b) => a.order_group - b.order_group)},
                    {name: 'nColumns', value: report.ncolumn},
                    {name: 'isGroupReport', value: true},
                    {name: 'filters', value: filters},
                    {
                        name: 'query',
                        value: report.isDrillDownQuery ? stateLocal.query : IsJsonString(report.query) ? JSON.parse(report.query) : undefined
                    },
                    {name: 'filtersByParent', value: filtersByParent},
                    {name: 'isLoading', value: State.RESOLVED}
                ]);
            } else {
                dispatchForm({name: 'isLoading', value: State.REJECTED});
            }
        } else {

            let noCacheQuery = !!baseActions && (!baseFilters || baseFilters.length === 0)

            if (typeof noCache === "boolean") {
                noCacheQuery = noCache
            }

            const responseQuery: ResponseReportQuery | MultiReport | null = await getReportQuery({
                report: report,
                user,
                reload,
                noCache: noCacheQuery
            });

            if (responseQuery) {

                const columns = removeDuplicateFromArrayDeepObj(responseQuery.columns ?? [], 'name')

                const allColumns = responseQuery.allColumns && responseQuery.allColumns.length > 0 ? responseQuery.allColumns : (responseQuery.allColumnsCopy ?? [])

                let data = responseQuery.data

                const common_data: { name: keyof InitialReducerState, value: any }[] = [
                    {name: 'data', value: data},
                    {name: 'copyData', value: data},
                    {name: 'remove_empty_columns', value: responseQuery.remove_empty_columns},
                    {name: 'columns', value: columns},
                    {name: 'copyColumns', value: columns},
                    {
                        name: 'hideColumns',
                        value: columns ? allColumns?.filter(column => !columns?.some(nColumn => nColumn.name === column.name)) : []
                    },
                    {
                        name: 'allColumns',
                        value: responseQuery.allColumns ?? [],
                    },
                    {name: 'report_in_progress', value: true},
                    {name: 'report', value: report},
                    {name: 'filters', value: responseQuery.filters},
                    {name: 'remove_empty_columns', value: responseQuery.remove_empty_columns},
                    {name: 'isLoading', value: State.RESOLVED},
                ]
                // console.log('responseQuery', responseQuery)


                if ((responseQuery as ResponseReportQuery).info) {
                    common_data.push({name: 'predictInfo', value: (responseQuery as ResponseReportQuery).info})
                }

                const query = IsJsonString(report.query) ? JSON.parse(report.query) : undefined

                if ((responseQuery as ResponseReportQuery)?.report_in_process) {
                    dispatchMultiForm([...common_data, {name: 'report_in_progress', value: true}]);
                } else {

                    dispatchMultiForm([...common_data, {name: 'report_in_progress', value: false},
                        {
                            name: 'query',
                            value: (report.isDrillDownQuery || stateLocal.isAsyncMlQuery) ? stateLocal.query ? stateLocal.query : query : query
                        }
                    ]);
                }

                if (responseQuery?.docKeys && Object.keys(responseQuery.docKeys).length > 0) {
                    // Change this service to a service by batches 3 in 3


// Here start the batch
//                     const batches: string[][] = [[]]
//                     let batchIndex = 0
                    const docsKey = responseQuery?.docKeys

                    const nData = data.map((item, index) => {
                        Object.keys(docsKey).forEach((column) => {
                            let itemKeys: string[] = [];

                            if (
                                item[column] &&
                                IsJsonString(item[column]) &&
                                Array.isArray(JSON.parse(item[column]))
                            ) {
                                itemKeys = JSON.parse(item[column]);
                            }

                            if (item[column] && Array.isArray(item[column])) {
                                itemKeys = item[column];
                            } else {
                                if (
                                    item[column] &&
                                    typeof item[column] === "string" &&
                                    item[column].length > 0
                                ) {
                                    if (item[column].includes("'")) {
                                        const temp = item[column].replaceAll("'", `"`)
                                        if (IsJsonString(temp) && Array.isArray(JSON.parse(temp))) {
                                            itemKeys = [...JSON.parse(temp)];
                                        } else {
                                            itemKeys = [item[column]];
                                        }
                                    } else {
                                        itemKeys = [item[column]];
                                    }
                                }
                            }

                            if (itemKeys.length > 0) {
                                item = {...item, [column]: itemKeys};
                            }
                        });
                        return item;
                    });

                    dispatchMultiForm([
                        {name: 'data', value: nData},
                        {name: 'copyData', value: nData}
                    ])

                    // Object.values(docsKey).flat().forEach((key) => {
                    //     if (batches[batchIndex].length === 2) {
                    //         batchIndex++
                    //         batches[batchIndex] = [key]
                    //     } else {
                    //         batches[batchIndex].push(key)
                    //     }
                    // })
                    //
                    // for await (const batch of batches) {
                    //     const documentsBatch = await getFilesDataByKeys(batch);
                    //
                    //     if (documentsBatch.item?.contents) {
                    //         const contents = documentsBatch.item?.contents
                    //         data.forEach((item, index) => {
                    //
                    //             Object.keys(docsKey).forEach((column) => {
                    //                 if (filesDataRef[`${column}_${index}`]) {
                    //                     item[column] = filesDataRef[`${column}_${index}`]
                    //                 }
                    //                 const temp: Content[] = [];
                    //                 let itemKeys: string[] = [];
                    //
                    //                 if (
                    //                     item[column] &&
                    //                     IsJsonString(item[column]) &&
                    //                     Array.isArray(JSON.parse(item[column]))
                    //                 ) {
                    //                     itemKeys = JSON.parse(item[column]);
                    //                 }
                    //
                    //                 if (item[column] && Array.isArray(item[column])) {
                    //                     itemKeys = item[column];
                    //                 } else {
                    //                     if (
                    //                         item[column] &&
                    //                         typeof item[column] === "string" &&
                    //                         item[column].length > 0
                    //                     ) {
                    //                         if (item[column].includes("'")) {
                    //                             const temp = item[column].replaceAll("'", `"`)
                    //                             if (IsJsonString(temp) && Array.isArray(JSON.parse(temp))) {
                    //                                 itemKeys = [...JSON.parse(temp)];
                    //                             } else {
                    //                                 itemKeys = [item[column]];
                    //                             }
                    //                         } else {
                    //                             itemKeys = [item[column]];
                    //                         }
                    //                     }
                    //                 }
                    //
                    //                 if (itemKeys.length > 0) {
                    //
                    //                     itemKeys.forEach((docKey) => {
                    //                         const document = contents.find(
                    //                             (content: Content) => content.key === docKey,
                    //                         );
                    //                         if (document) {
                    //                             temp.push(document);
                    //                         }
                    //                     });
                    //
                    //                     // setFilesDataRef(prevState => ({
                    //                     //     ...prevState,
                    //                     //     [`${column}_${index}`]: temp
                    //                     // }))
                    //                 }
                    //
                    //
                    //             });
                    //         });
                    //     }
                    // }


                }
            } else {
                dispatchForm({name: 'isLoading', value: State.REJECTED});
            }
        }
    }

    const getReportDetail = async () => {
        const key = reportKey ?? reportKeyProp
        if (key) {
            dispatchForm({name: 'isLoading', value: State.PENDING});
            const response: SbxResponse<Report> = await findByModel({
                row_model: 'sbx_crm_report',
                where: {keys: [key as string]}
            });


            let reload = from === "report_generator"

            if (response.success && response.results) {
                const skipFirstExecution = response.results[0]?.skip_execution_on_open


                dispatch(routerActions.changeActive(response.results[0]?.name));
                setReportName(response.results[0]?.name ?? "")
                const query = IsJsonString(response.results[0].query) ? JSON.parse(response.results[0].query) : undefined

                if (skipFirstExecution) {
                    dispatchForm({name: 'isLoading', value: State.RESOLVED});

                    dispatchMultiForm([
                        {name: 'isLoading', value: State.RESOLVED},
                        {name: 'query', value: {...query}},
                        {name: 'report', value: response.results[0]},
                        {name: 'isLoading', value: State.RESOLVED}
                    ])
                }

                if (!reload) {
                    reload = validateTruncateReload(response.results[0])
                }


                if (query) {
                    const actions = query.actions.flat() as AnalyticQueryAction[]
                    if (actions.some(action => action.type === 'async_ml')) {
                        dispatchMultiForm([
                            {name: 'isAsyncMlQuery', value: true},
                            {name: 'query', value: {...query, actions}},
                            {name: 'report', value: response.results[0]},
                            {name: 'isLoading', value: State.RESOLVED},
                            {
                                name: 'predictInfo',
                                value: {}
                            }
                        ])
                        if (reload) {
                            setTrainingStatus('training_model')
                            if (!skipFirstExecution) {
                                launchQuery({
                                    ...response.results[0],
                                    query: JSON.stringify({...query, actions})
                                }, false, true)
                            }
                        }

                    } else {
                        if (!skipFirstExecution) {
                            launchQuery(response.results[0], reload)
                        }

                    }
                } else {
                    if (!skipFirstExecution) {
                        launchQuery(response.results[0], reload)
                    }
                }
            } else {
                dispatchForm({name: 'isLoading', value: State.REJECTED});
            }
        }
    };

    React.useEffect(() => {
        getReportDetail();
    }, [reportKey, reportKeyProp]);

    useAsyncEffect(async () => {

        if (stateLocal.report) {
            if (stateLocal.report?.truncate && IsJsonString(stateLocal.report.truncate as string) && !truncate) {
                const nTruncate = JSON.parse(stateLocal.report.truncate as string) as TruncateReport

                if (nTruncate.models_and_fields && nTruncate.models_and_fields.length > 0) {
                    const baseTruncate = {model: nTruncate.model, field: nTruncate.field, name: nTruncate.name}
                    nTruncate.models_and_fields.push(baseTruncate as ModelsAndFields)
                }

                setTruncate(nTruncate)
            }

            if (stateLocal.report.query && IsJsonString(stateLocal.report.query)) {
                const query = JSON.parse(stateLocal.report.query)
                if (query.truncate) {

                    if (query.truncate.models_and_fields && query.truncate.models_and_fields.length > 0) {
                        const baseTruncate = {
                            model: query.truncate.model,
                            field: query.truncate.field,
                            name: query.truncate.name
                        }
                        query.truncate.models_and_fields.push(baseTruncate as ModelsAndFields)
                    }


                    if (!truncate) {
                        setTruncate(query.truncate)
                    }

                }
            }

            const response = await getLastReportUpdate(stateLocal.report)

            if (response?.success) {
                setTime(response.time)
            }

            if (!stateLocal.report.type) {
                setShowConfig(true)
            }
        }
    }, [stateLocal.report]);

    useAsyncEffect(async () => {

        let interval: NodeJS.Timeout | string | number | undefined = undefined

        if (stateLocal.report_in_progress && stateLocal.report) {

            interval = setInterval(async () => {
                if (stateLocal.report) {
                    const response = await getLastReportUpdate(stateLocal.report)

                    if (response.time) {
                        launchQuery(stateLocal.report)
                        clearInterval(interval);
                    }
                }

            }, 1000 * 5);
        }

        return () => {
            clearInterval(interval)
        };
    }, [stateLocal.report_in_progress, stateLocal.report, time]);

    React.useEffect(() => {

        if (reportName) {
            dispatch(
                routerActions.changeBreadcrumb([
                    {
                        label: t("routes:crm-reports"),
                        path: "/analytics/crm-reports",
                    },
                    {
                        label: reportName,
                        active: true,
                    },
                ])
            );
        }

    }, [reportName]);

    const reloadReport = (report: Report) => {

        if (stateLocal.isAsyncMlQuery) {

            setTrainingStatus('training_model')
            dispatchForm({
                name: 'predictInfo',
                value: {}
            })
            launchQuery({
                ...report,
                query: stateLocal.query ? JSON.stringify(stateLocal.query) : report.query
            }, false, true)
        } else {
            let alias: Alias | null = null;
            if (report.alias) {
                alias = JSON.parse(report.alias as string)
            }

            let reload = validateTruncateReload({
                ...report,
                truncate: truncate ? JSON.stringify(truncate) : undefined,
            })

            if (reload) {
                toast({message: `${t("report")} ${t("in_progress")}`, type: "info"})
            }

            launchQuery({
                ...report,
                truncate: truncate ? JSON.stringify(truncate) : undefined,
                alias: alias ? JSON.stringify({
                    key: alias.key,
                    ttl: alias.ttl,
                    update: true
                }) : undefined
            }, reload)
        }


    }

    const isValidUserForBot = () => {

        if (user?.config?.sbx_crm?.ai_context?.available_users && user?.config?.sbx_crm?.ai_context?.available_users.length > 0) {

            return user?.config?.sbx_crm?.ai_context?.available_users.includes(user?.email ?? "") && !!user?.config?.sbx_crm?.ai_context?.business
        } else {
            return !!user?.config?.sbx_crm?.ai_context?.business
        }


    }

    return (
        <div>
            <>
                <div className="d-flex flex-column flex-lg-row justify-content-between">

                    {!reportKeyProp &&
                        <>
                            <h4 className="text-primary fw-bold">{t("report")}: {reportName}</h4>

                            <div className="d-flex justify-content-lg-end gap-2 mb-3 align-items-start">

                                <div>
                                    <ButtonComponent label={t("filters")} onClick={toggleFilters} icon={faFilter}
                                                     color={"primary"}/>
                                </div>

                                <Button color={"primary"} onClick={() => {
                                    if (stateLocal.report) {
                                        reloadReport(stateLocal.report)
                                    } else {
                                        if (stateLocal.multiReports && stateLocal.multiReports.length > 0) {
                                            for (const report of stateLocal.multiReports) {
                                                reloadReport(report)
                                            }
                                        }
                                    }
                                }}>
                                    <FontAwesomeIcon icon={faSyncAlt}
                                                     color={"white"}/> {stateLocal.isAsyncMlQuery ? "Re" : ""} {t(stateLocal.isAsyncMlQuery ? "report:train" : "reload")}
                                </Button>

                                <Permission permission={permission}>

                                    <Tooltip title={`${t("last_update")}: ${time}`}>
                                        <Button color={"info"}
                                                onClick={() => history.push('/analytics/report-generator/' + reportKey)}>
                                            <FontAwesomeIcon icon={faEdit} color={"white"}/> {t("update")} {t("report")}
                                        </Button>
                                    </Tooltip>
                                </Permission>

                                {tab === "1" && <div className="d-flex justify-content-end">
                                    <ButtonComponent
                                        label={`${t(showConfig ? "hide" : "show")} ${t("report:report_setting")}`}
                                        icon={showConfig ? faEyeSlash : faEye}
                                        onClick={() => setShowConfig(prevState => !prevState)}
                                        color={"secondary"}/>
                                </div>}


                            </div>


                        </>
                    }
                </div>

                {stateLocal.isAsyncMlQuery &&
                    stateLocal.query &&
                    <ReportAsyncMlComponent trainingStatus={trainingStatus} setTrainingStatus={setTrainingStatus}
                                            report={stateLocal.report} predictInfo={stateLocal.predictInfo}
                                            launchQuery={report => {
                                                launchQuery({
                                                    ...report,
                                                }, false, true)
                                            }} loadingData={stateLocal.isLoading} data={stateLocal.data}
                                            query={stateLocal.query}/>}

                {(!stateLocal.isAsyncMlQuery || stateLocal.columns.length > 0) && <>

                    {stateLocal.report_in_progress && <div className="card ">
                        <div className="d-flex justify-content-center align-items-center gap-2 p-2">
                            <h6 className="m-0">{t("last_update")} {t("in_progress")} ({t("custom-message:waiting_message")})</h6>{" "}
                            <FontAwesomeIcon icon={faSpinner} size={"1x"} spin/>
                        </div>
                    </div>}


                    {stateLocal.isGroupReport ? <div className="d-flex flex-column">
                            {stateLocal.multiReports && stateLocal.multiReports.length > 0 &&
                                <MultiReportsComponent reports={stateLocal.multiReports} filters={stateLocal.filters}
                                                       filtersByParent={stateLocal.filtersByParent}
                                                       columns={stateLocal.nColumns}
                                                       setLoading={state => dispatchForm({name: 'isLoading', value: state})}
                                                       isLoading={stateLocal.isLoading === State.PENDING}/>}

                        </div> :
                        <ReportComponent report={stateLocal.report} tab={tab} setTab={setTab} showConfig={showConfig}
                                         truncate={truncate}
                                         setTruncate={setTruncate} reportProps={{
                            columns: stateLocal.columns,
                            filters: stateLocal.filters, hideColumns: stateLocal.hideColumns,
                            remove_empty_columns: stateLocal.remove_empty_columns
                        }} data={stateLocal.data} query={stateLocal.query}
                                         setReportColumnsProps={columns => {
                                             dispatchForm({
                                                 name: "columns",
                                                 value: columns
                                             })
                                         }}
                                         launchQuery={launchQuery}
                                         isDashboardView={isDashboardView}
                                         isLoading={stateLocal.isLoading === State.PENDING}/>}

                </>}


                {openFilters && stateLocal.query &&
                    <ReportFiltersByQueryComponent
                        allResults={stateLocal.copyData}
                        results={stateLocal.data}
                        allColumns={stateLocal.copyColumns}
                        report={stateLocal.report}
                        setCurrentColumns={columns => {
                            dispatchForm({
                                name: "columns",
                                value: columns
                            })
                        }}
                        setFilters={setFilters}
                        filters={filters}
                        setResults={async data => {
                            dispatchForm({name: 'data', value: data})
                        }}
                        isOpen={openFilters}
                        toggle={toggleFilters}
                    />
                }
            </>

            {isValidUserForBot() &&
                stateLocal.query && stateLocal.report &&
                <ReportChatBotComponent report={stateLocal.report} query={stateLocal.query}/>}

        </div>
    );
};

export default ReportDetail;
