import React, {useContext, useEffect, useState} from 'react';
// core components
// @ts-ignore
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import CustomTableComponent, {Column} from "../Shared/CustomTableComponent/CustomTableComponent";
import useTranslate from '../../hooks/useTranslate';
import ModalComponent from "../Shared/Modal/ModalComponent/ModalComponent";
import QueryComponent from "../Shared/QueryComponent/QueryComponent";
import PopoverComponent from "../Shared/PopoverComponent";
import {Button, Input, ListGroup, ListGroupItem} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faCheckCircle, faHistory, faLayerGroup, faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import {capitalize, filterColumnsToGroup, groupByHead, HistoryLocal} from "../../utils";
import SmallTextComponent from "../Shared/FieldComponents/SmallTextComponent";
import {EmailContext} from "./index";
import {State} from "../../types/State";
import useAsyncEffect from "../../hooks/useAsyncEffect";
import {emailDataToSend} from "../../services/backend/EmailService";
import TabContents from "../Shared/TabContents";

interface Iprops {
  uploadData?: () => void;
}

const TableData = ({uploadData}: Iprops) => {

  const {
    columns,
    allColumns,
    data,
    setData,
    setColumns,
    setOldData,
    oldData,
    query,
    setQuery,
    page,
    totalData,
    setTotalData,
    setPage,
    loading,
    onFinish,
    setLoading
  } = useContext(EmailContext);

  const {t} = useTranslate("email-blast");

  const [fileName, setFileName] = useState("");
  const [tab, setTab] = useState("0");
  const [history] = useState(new HistoryLocal("queries"));
  const [groupBy, setGroupBy] = useState("");
  const [filterColumn, setFilterColumn] = useState("");
  const [open, openModal] = useState(false);
  const [size, setSize] = useState<"xl" | "auto">("xl");

  const processData = (dataString: string) => {
    setData([]);
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
      if (headers && row.length == headers.length) {
        const obj: any = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"')
              d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"')
              d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }
        if (Object.values(obj).filter(x => x).length > 0) {
          list.push(obj);
        }
      }
    }
    const columnList: Column[] = headers.map(c => ({name: c, header: c}));
    setColumns(columnList);
    setData(list);
    setQuery && setQuery(undefined);
    setTotalData && setTotalData(undefined);
    setPage && setPage(false);
    resetGroup();
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target?.files) {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt: ProgressEvent<FileReader>) => {
          /* Parse data */
          if (evt?.target?.result) {
            const bstr = evt.target.result;
            const XLSX = require("xlsx");

            const wb = XLSX.read(bstr, {type: 'binary'});
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws);
            setFileName(file.name || "");
            processData(data);
          }
        };
        reader.readAsText(file, "UTF-8");
        reader.readAsBinaryString(file);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!open) {
      setSize("xl");
    }
  }, [open]);

  useAsyncEffect(async () => {
    if (groupBy) {
      setTotalData && setTotalData(undefined);
      let newData = data;

      if (query && setLoading) {
        setLoading(State.PENDING);
        const res: any = await emailDataToSend(columns, 0, query, {allData: true});
        if (res.success) {
          newData = res.items;
        }
        setPage && setPage(false)
        setLoading(State.RESOLVED);
      }

      if (!oldData.columns && !oldData.data && !oldData.totalData && !oldData.page) {
        setOldData({data, columns, totalData, page: page === false ? 1 : page});
      }

      const {
        columns: c,
        rows
      } = groupByHead(groupBy, false, oldData.data ?? newData, ["_META"]) as { rows: any[], columns: Column[] };
      const cc = (oldData.columns ?? columns).filter(c1 => !c.some(c2 => c2.name === c1.name));
      const newColumns = [...cc, ...c];
      setColumns(newColumns);
      setData(rows);
      setTotalData && setTotalData(undefined)

    }
  }, [groupBy, query]);

  function resetGroup() {
    setOldData({});
    setGroupBy("");
    setFilterColumn("");
  }

  const onToggle = () => {
    openModal(false);
    const elem = document.getElementById("file-upload") as any;
    if (elem && elem.value) {
      elem.value = "";
      setFileName("");
      setData([]);
      setColumns([]);
      resetGroup();
    }
  }

  return (
    <>
      <div className="border-0 p-4">
        <div className="mb-2 d-flex align-items-center">
          <div>
            <label className="btn btn-light btn-sm" htmlFor="file-upload">{t("select-file")}</label>
            <b className="ms-2">{fileName}</b>
            <input
              id="file-upload"
              className="d-none"
              type="file"
              accept=".csv"
              onChange={e => handleFileUpload(e)}
            />
          </div>
          <div>
            <button
              onClick={() => {
                !uploadData ? openModal(true) : uploadData()
              }}
              className="btn btn-primary btn-sm">
              {t(`common:${!uploadData ? "sbx_query" : "upload_file"}`)}
            </button>
          </div>
        </div>
        {!!columns.length && !!data.length && <div className="d-flex justify-content-end">

          {oldData.columns && <Button color="link" className="text-primary mb-2" onClick={() => {
            if (oldData.columns && oldData.data) {
              setColumns(oldData.columns);
              setData(oldData.data);
              setTotalData && setTotalData(oldData.totalData);
              setPage && setPage(1);
              resetGroup();
            }
          }}> <FontAwesomeIcon icon={faSyncAlt}/>
            <small> {t("common:clear")} </small></Button>}


          <PopoverComponent
            label={<span className="btn btn-primary btn-sm pointer">
                  <FontAwesomeIcon icon={faLayerGroup}/> Group By <FontAwesomeIcon icon={faCaretDown}/>
                </span>} trigger={"click"}
            placement={"bottom"} id={"group_by"}>
            <div>
              <SmallTextComponent
                placeholder="Search head..."
                id={"filter_columns"}
                name="filter_columns"
                value={filterColumn}
                onChange={setFilterColumn}/>
              <div style={{maxHeight: "50vh"}}
                   className="overflow-auto">
                <ListGroup>
                  {filterColumnsToGroup(columns, filterColumn).map(c => {

                    return (
                      <ListGroupItem key={c.name} className="my-1">
                        <label className="pointer" htmlFor={c.name}>
                          <Input
                            checked={groupBy === c.name} id={c.name}
                            onChange={() => setGroupBy(c.name)}
                            type="radio"
                            name="group"
                            className="mr-1"/>
                          {capitalize(c.header.split("_").join(" "))}
                        </label>
                      </ListGroupItem>
                    )
                  })}
                </ListGroup>

              </div>
            </div>
          </PopoverComponent>
        </div>}
        <CustomTableComponent
          key={'table 1'}
          loading={loading === State.PENDING}
          onChangePage={p => onFinish && (page !== false) && onFinish(columns, allColumns, p)}
          currentPage={typeof page === "number" ? page : undefined}
          columnsSetting
          columns={columns}
          data={data}
          totalData={totalData}
        />
      </div>
      <ModalComponent
        title={t("common:sbx_query")}
        size={size}
        isOpen={open}
        noFooter={true}
        toggle={onToggle}>
        <TabContents
          tap={tab}
          getCurrentTab={setTab}
          tabs={[{
            component: <QueryComponent
              showTable
              query={query}
              getQuery={setQuery}
              getSizeColumns={size => setSize(size > 10 ? "auto" : "xl")}
              getResult={(c, r, allColumn) => {
                onToggle();
                resetGroup();
                history.setItem(query, query?.row_model);
                if (onFinish) {
                  onFinish(c, allColumn, 1);
                }
              }}/>,
            label: "Query builder"
          },
            {
              label: <span><FontAwesomeIcon icon={faHistory}/> History</span>,
              component: (
                <div>
                  <CustomTableComponent
                    useLocalPage
                    key={"table-history"}
                    actions={[{
                      type: "primary",
                      label: <FontAwesomeIcon icon={faCheckCircle}/>,
                      onAction: e => {
                        setQuery && setQuery(e.value);
                        setTab("0")
                      }
                    }]}
                    columns={[
                      {
                        header: "Date",
                        name: "date",
                        type: "DateTime"
                      },
                      {
                        header: "Name",
                        name: "name",
                        type: "String"
                      },
                      {
                        header: "Query",
                        name: "value",
                        type: "Json",
                        zIndex: 10000
                      }
                    ]}
                    data={history.getItem()}/>
                </div>
              )
            }]}/>
      </ModalComponent>
    </>
  );
};

export default TableData;
