import {
  Permissions,
  PermissionsData,
  PermissionType,
} from "../types/Permissions";
import { ConfigByRoleInfo } from "../types/User";
import { ConfigData } from "../types/ConfigData";
import { grantPermission, IsJsonString } from "../utils";
import { AnyData } from "../types/AnyData";
import { tabsRegisteredName } from "../components/Layouts/AdminLayout/SideBarContent";

export interface RouteData {
  permission: Permissions | Permissions[];
  name: string;
  path?: string;
  root: string;
  label?: string;
  views?: RouteData[];
}

export interface ViewProcess {
  [process_id: string]: {
    // procesos para filtrar
    tasks: number[]; //tasks a filtrar
    action: {
      [task_id: string]: number
    }; //action a llamar por tarea
    forms: {
      //informacion de renderizacion del modal
        [task_id: string]: {
          [form_id: string]: string[]; //campos a mostrar
        }

    };
    label: { [task_id: string]: string }; //Nombre del boton cuando se elijan de este proceso por tarea
  };
}

export interface ViewFormat {
  permission: Permissions | Permissions[];
  name: string;
  path?: string;
  root: string;
  domain?: number[];
  label?: string;
  hide?: boolean;
  checkAllowMetadata?: boolean;
  process?: ViewProcess;
  config_by_role?: { [role: string]: ConfigByRoleInfo };
}

export interface ViewProps extends ViewFormat {
  views?: ViewFormat[];
}

export interface Route {
  permission: Permissions | Permissions[];
  name: string;
  icon?: any;
  label?: string;
  path?: string;
  domain?: number[];
  views?: ViewProps[];
  hide?: boolean;
  key?: string;
  config_by_role?: { [role: string]: ConfigByRoleInfo };
}

const routes: Route[] = [
  {
    name: "home",
    icon: "pe-7s-diamond",
    permission: Permissions.NO_VALIDATE,
    views: [
      {
        path: "/accounts",
        name: "accounts",
        root: "/home",
        permission: Permissions.ACCOUNT,
      },
      {
        root: "/home",
        name: "calendar",
        path: "/calendar",
        permission: Permissions.CALENDAR,
      },
      {
        path: "/inbox",
        name: "myTasks",
        root: "",
        permission: Permissions.TASKS,
      },
      {
        path:
          typeof window !== "undefined"
            ? window.location.origin + "/web-designer/#/"
            : "",
        name: "Web designer",
        root: "",
        permission: Permissions.NO_VALIDATE,
        domain: [129, 281],
      },
      {
        path:
          typeof window !== "undefined"
            ? window.location.origin + "/omnichannel/#/"
            : "",
        name: "Omnichannel",
        root: "",
        permission: Permissions.NO_VALIDATE,
        domain: [129, 281],
      },

      {
        path: "/driver-tasks?process_id=4",
        name: "bulkRoutes",
        root: "/admin",
        domain: [272],
        permission: Permissions.NO_VALIDATE,
      },
    ],
  },
  {
    name: "sales",
    icon: "pe-7s-piggy",
    permission: Permissions.SALES_GOAL,
    views: [
      {
        path: "/sales-goal",
        name: "sales-goal",
        root: "/sales",
        permission: Permissions.SALES_GOAL,
      },
      {
        path: "/purchase-by-month",
        name: "purchase-by-month",
        permission: Permissions.NO_VALIDATE,
        root: "/sales",
      },
    ],
  },
  {
    name: "analytics",
    icon: "pe-7s-helm",
    permission: Permissions.REPORT,
    views: [
      {
        path: "/customer-cohorts",
        name: "customerCohorts",
        root: "/analytics",
        permission: Permissions.REPORT,
        checkAllowMetadata: true,
      },
      {
        path: "/productgroup",
        name: "productGroup",
        root: "/analytics",
        permission: Permissions.REPORT,
        checkAllowMetadata: true,
      },
      {
        path: "/marketing",
        name: "Marketing UTM",
        root: "/analytics",
        permission: Permissions.REPORT,
        checkAllowMetadata: true,
      },
      {
        path: "/",
        name: "customerGrowth",
        root: "/analytics/customer-growth",
        permission: Permissions.REPORT,
        checkAllowMetadata: true,
      },
      {
        path: "/",
        name: "customerEvents",
        root: "/analytics/customer-events",
        permission: Permissions.REPORT,
        checkAllowMetadata: true,
      },
      {
        path: "/",
        name: "claims",
        root: "/analytics/claims",
        permission: Permissions.REPORT,
        checkAllowMetadata: true,
      },
      {
        path: "/",
        name: "products-sold",
        root: "/analytics/products-sold",
        permission: Permissions.REPORT,
        checkAllowMetadata: true,
      },
      {
        path: "/",
        name: "inventory",
        root: "/analytics/inventory",
        permission: Permissions.REPORT,
        checkAllowMetadata: true,
      },
      {
        path: "/re-order",
        name: "purchase-by-re-order",
        root: "/analytics/purchase",
        permission: Permissions.REPORT,
        checkAllowMetadata: true,
      },
      {
        path: "/products-sold/most-sold",
        name: "most-sold",
        root: "/analytics",
        permission: Permissions.REPORT,
        checkAllowMetadata: true,
      },
      {
        path: "/search-terms",
        name: "search-terms",
        root: "/analytics",
        permission: Permissions.REPORT,
        checkAllowMetadata: true,
      },
    ],
  },
  {
    name: "process",
    icon: "pe-7s-hourglass",
    permission: Permissions.PROCESS_MODEL,
    views: [
      {
        path: "/",
        name: "projectManager",
        root: "/admin/project-manager",
        permission: Permissions.NO_VALIDATE,
      },
      {
        path: "/list",
        name: "projectList",
        root: "/admin/project-manager",
        permission: Permissions.PROCESS_MODEL_READ,
      },
      {
        path: "/launch",
        name: "launchProcess",
        root: "/admin/project-manager",
        permission: Permissions.NO_VALIDATE,
      },
    ],
  },
  {
    name: "forms",
    icon: "pe-7s-safe",
    permission: Permissions.FORM_BUILDER,
    views: [
      {
        path: "/form-list",
        name: "form-list",
        root: "/forms",
        permission: Permissions.FORM_BUILDER,
      },
    ],
  },
  {
    name: "communications",
    icon: "pe-7s-headphones",
    permission: Permissions.EMAIL_TEMPLATE,
    views: [
      {
        path: "/email-blast",
        name: "emailBlast",
        root: "/communications",
        permission: Permissions.EMAIL_TEMPLATE,
      },
      {
        path: "/template-generator",
        name: "template-generator",
        root: "/communications",
        permission: Permissions.EMAIL_TEMPLATE,
      },
    ],
  },
  {
    name: "data-providers",
    icon: "pe-7s-next-2",
    permission: Permissions.DATA_PROVIDER,
    views: [
      {
        path: "/data-provider",
        name: "data-providers",
        root: "/providers",
        permission: Permissions.DATA_PROVIDER_READ,
      },
    ],
  },
  // {
  //   name: "example-sub-route",
  //   icon: "pe-7s-cloud-download",
  //   permission: Permissions.NO_VALIDATE,
  //   views: [{
  //     name: "example",
  //     root: "/example",
  //     permission: Permissions.NO_VALIDATE,
  //     path: "/example",
  //     views: [
  //       {
  //         path: "/example",
  //         name: "example",
  //         root: "/example/example",
  //         permission: Permissions.NO_VALIDATE
  //       }
  //     ]
  //   }]
  // },
  {
    name: "data",
    icon: "pe-7s-cloud-download",
    permission: Permissions.DATA_MANAGER,
    views: [
      // {
      //   path: "/products",
      //   name: "products",
      //   root: "/admin",
      //   permission: Permissions.NO_VALIDATE
      // }
    ],
  },
  {
    name: "proceedings",
    icon: "pe-7s-news-paper",
    permission: Permissions.NO_VALIDATE,
    views: [],
  },
  {
    name: "bulk_updates",
    icon: "pe-7s-upload",
    permission: Permissions.NO_VALIDATE,
    views: [],
  },
  {
    name: "reports",
    icon: "pe-7s-display1",
    permission: Permissions.REPORT,
    views: [
      {
        name: "crm-reports",
        root: "/analytics",
        permission: [Permissions.REPORT_READ, Permissions.REPORT_EXECUTE],
        path: "/crm-reports",
      },
      {
        name: "report-generator",
        root: "/analytics",
        permission: Permissions.REPORT_CREATE,
        path: "/report-generator",
      },
      // {name: "report-generator2", root: "/analytics", permission: Permissions.REPORT_CREATE, path: "/report-generator"},
    ],
  },
  {
    name: "config",
    icon: "pe-7s-settings",
    permission: [Permissions.USER, Permissions.APP_CONFIG],
    views: [
      {
        path: "/config",
        name: "app",
        root: "/admin",
        permission: Permissions.APP_CONFIG,
      },
      {
        path: "/config/manager",
        name: "App-manager",
        root: "/admin",
        permission: Permissions.APP_CONFIG,
      },
      {
        path: "/admin_users",
        name: "admin-users",
        root: "/admin/users",
        permission: Permissions.USER_READ,
      },
      {
        path: "/permissions-assignment",
        name: "permissions-assignment",
        root: "/permissions",
        permission: Permissions.PERMISSION_ASSIGNMENT,
      },
      {
        path: "/groups_manager",
        name: "groups_manager",
        root: "/permissions",
        permission: [Permissions.GROUP],
      },
      {
        path: "/",
        name: "crm_execution",
        root: "/admin/crm_execution",
        permission: Permissions.APP_CONFIG,
      },
    ],
  },
];

export default routes;

function combineRoutes(externalRoutes: Route[]): Route[] {
  const configRoutes = routes.reduce((routes: Route[], route) => {
    const newRoute = { ...route };

    if (externalRoutes.length) {
      const nRoute = externalRoutes.find(
        (cRoute) => cRoute.name === newRoute.name,
      );
      if (nRoute) {
        newRoute.name = nRoute.name;
        if (nRoute.permission) {
          newRoute.permission = nRoute.permission;
        }

        newRoute.hide = nRoute.hide ?? false;

        newRoute.views = newRoute.views?.reduce((views: ViewProps[], view) => {
          if (nRoute.views) {
            const nView = nRoute.views.find(
              (cView) => cView.name === view.name,
            );

            if (nView) {
              views.push(nView);
            } else {
              views.push(view);
            }
          } else {
            views.push(view);
          }

          return views;
        }, []);
      }

      if (nRoute?.views) {
        nRoute.views.forEach((view) => {
          if (!newRoute.views?.some((v) => v.name === view.name)) {
            newRoute.views?.push(view);
          }
        });
      }

      routes.push(newRoute);
    }

    return routes;
  }, []);
  // setUserRoutes(config.sbx_crm.routes);

  const newRoutes = externalRoutes.filter(
    (nRoute) =>
      !configRoutes.some((cRoute) => cRoute.name === nRoute.name) &&
      nRoute.views &&
      nRoute.views.length > 0 &&
      nRoute.views.every((view) => view.name && view.root && view.path),
  );

  return [...configRoutes, ...newRoutes].filter((route) => !route.hide);
}

function indexingRoutes(
  routes: Route[],
  config: ConfigData["config"],
  permissions: PermissionsData[],
  permissionsMapper: { [key: string]: Permissions },
  domains: { id: number }[],
  t: (key: string) => string,
) {
  if (
    config &&
    config?.sbx_crm!?.data &&
    Object.keys(config?.sbx_crm!?.data).length > 0
  ) {
    const domainsAvailable = domains.map((domain) => domain.id);

    let allowDataManagerKeys: string[] = [];

    const permission_data_manager_read = permissions.find(
      (permission) =>
        permission.module_name === Permissions.DATA_MANAGER &&
        permission.permission === PermissionType.READ,
    );

    if (
      permission_data_manager_read?.metadata &&
      Array.isArray(permission_data_manager_read?.metadata)
    ) {
      permission_data_manager_read.metadata.forEach((meta) => {
        if (
          IsJsonString(meta) &&
          JSON.parse(meta).allow &&
          JSON.parse(meta).allow.length > 0
        ) {
          const allow = JSON.parse(meta).allow;
          allowDataManagerKeys = allowDataManagerKeys.concat(
            allow.filter(
              (route: string | number) =>
                typeof route === "string" && route !== "all",
            ),
          );
        }
      });
    }

    return routes
      .filter((route) =>
        route.domain
          ? domainsAvailable.some((d) =>
              (route.domain ?? []).some((d1) => d === d1),
            )
          : true,
      )
      .map((rou) => {
        let route = Object.assign({}, rou);
        const tab = tabsRegisteredName.find((t) => route.name === t);

        if (tab) {
          let configData: AnyData = config.sbx_crm[tab];

          const tabRoute = config.sbx_crm?.routes?.find(
            (r) => r.name === route.name,
          );

          if (tabRoute) {
            route.permission = tabRoute.permission;
          }

          if (configData) {
            route.views = Object.keys(configData)
              .filter((key) => key !== "root" && key !== "cs")
              .filter((key) => !configData[key].tag)
              .map((data) => {
                if (
                  configData[data].items &&
                  configData[data].items.length > 0
                ) {

                  return {

                    name: data,
                    label: data,
                    root: "/",
                    path: ``,
                    permission:
                        configData[data].permission ?? Permissions.NO_VALIDATE,
                    views: configData[data].items
                        .filter((item: Route) =>
                            (allowDataManagerKeys.includes(data)) ||
                            (Array.isArray(item.permission)
                                ? item.permission.some((permission) =>
                                    checkAllowMetadataPermission(
                                        permission,
                                        item.key ?? "",
                                        permissions,
                                    ),
                                )
                                : checkAllowMetadataPermission(
                                    item.permission,
                                    item.key ?? "",
                                    permissions,
                                )),
                        )
                      .filter((item: Route) =>
                        allowDataManagerKeys.length > 0
                          ? (allowDataManagerKeys.includes(item.name) || allowDataManagerKeys.includes(data))
                          : true,
                      )
                      .map((item: Route) => {
                        return {
                          name: item.name,
                          label: item.name,
                          root: "",
                          path: (item.path ?? "") + item.key,
                          permission: item.permission,
                        };
                      }),
                  };
                } else {
                  if (Array.isArray(configData[data])) {
                    return {
                      name: data,
                      label: t(data),
                      root: configData.root ? `/${configData.root}` : "/config",
                      path: `/${tab}`,
                      permission: Permissions.NO_VALIDATE,
                      views: configData[data]
                          .filter((key: string) => {
                            const name = configData[key].label ?? configData[key].name ?? key;

                            return allowDataManagerKeys.length > 0
                                ? (allowDataManagerKeys.includes(key) || allowDataManagerKeys.includes(data) || allowDataManagerKeys.includes(name))
                                : true
                          },
                        )
                        .map((key: string) => {
                          const name = configData[key].label ?? key;
                          return {
                            label: name,
                            root: configData.root
                              ? `/${configData.root}`
                              : "/config",
                            name: name,
                            path: `/${tab}/${key}`,
                            permission: Permissions.NO_VALIDATE,
                          };
                        }),
                    };
                  }
                }

                if (
                  allowDataManagerKeys.length > 0 &&
                  !allowDataManagerKeys.includes(data)
                ) {
                  return {
                    name: "",
                    label: "",
                    root: "",
                    path: "",
                    views: [],
                    permission: "",
                  };
                }

                return {
                  name: configData[data].label,
                  root: configData[data].root
                    ? `/${configData[data].root}`
                    : "/config",
                  path: `/${tab}/${
                    configData[data].root
                      ? `${configData[data].label}/${
                          configData[data].cs ?? configData[data].name
                        }`
                      : data
                  }`,
                  permission: Permissions.NO_VALIDATE,
                };
              })
              .filter(
                (r) =>
                  r.name &&
                  grantPermission(r.permission, permissionsMapper ?? {}) &&
                  (r.views ? r.views.length > 0 : true),
              );

          } else {
            route.views = [];
          }
        } else {
          route.views = route.views
            ?.filter((r) =>
              grantPermission(r.permission, permissionsMapper ?? {}),
            )
            .filter((route) =>
              route.domain
                ? domainsAvailable.some((d) =>
                    (route.domain ?? []).some((d1) => d === d1),
                  )
                : true,
            );
        }
        return route;
      })
      .filter((r) => grantPermission(r.permission, permissionsMapper ?? {}))
      .filter((r) => r.views?.length);
  } else {
    const domainsAvailable = domains.map((domain) => domain.id);
    return routes
      .filter((route) => {
        if (route.domain) {
          return domainsAvailable.some((d) =>
            (route.domain ?? []).some((d1) => d === d1),
          );
        } else {
          return true;
        }
      })
      .map((rou) => {
        let route = Object.assign({}, rou);
        route.views = route.views
          ?.filter((r) =>
            grantPermission(r.permission, permissionsMapper ?? {}),
          )
          .filter((route) =>
            route.domain
              ? domainsAvailable.some((d) =>
                  (route.domain ?? []).some((d1) => d === d1),
                )
              : true,
          );

        route.views = route.views?.filter((r) => {
          if (r.checkAllowMetadata) {
            if (Array.isArray(r.permission)) {
              return r.permission.some((permission) =>
                checkAllowMetadataPermission(permission, r.name, permissions),
              );
            } else {
              return checkAllowMetadataPermission(
                r.permission,
                r.name,
                permissions,
              );
            }
          } else {
            return true;
          }
        });

        return route;
      })
      .filter((r) => r.views?.length);
  }
}

const checkAllowMetadataPermission = (
  permission_name: Permissions,
  itemToCheck: string,
  permissions: PermissionsData[],
) => {
  if (
    permissions.some((permission) => permission.module_name === permission_name)
  ) {
    const permission = permissions.find(
      (permission) =>
        permission.module_name === permission_name &&
        permission.permission === "EXECUTE",
    );
    if (permission) {
      const metadata = permission.metadata?.filter((meta) => meta);
      if (
        metadata &&
        metadata.length > 0 &&
        IsJsonString(permission?.metadata?.[0] || "") &&
        JSON.parse(metadata[0])?.allow!?.length > 0
      ) {
        const allow = JSON.parse(permission.metadata?.[0] || "").allow;
        if (allow.includes("all")) {
          return true;
        } else {
          return allow.includes(itemToCheck);
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const mappingRoutes = (
  config: ConfigData["config"],
  permissions: PermissionsData[],
  permissionsMapper: { [key: string]: Permissions },
  domains: { id: number }[],
  t: (key: string) => string,
) => {
  let otherRoutes = config?.sbx_crm.routes ?? [];

  otherRoutes = indexingRoutes(
    combineRoutes(otherRoutes),
    config,
    permissions,
    permissionsMapper,
    domains,
    t,
  );

  return otherRoutes;
};
