import React, {createContext, useRef, useState} from "react";

// layout for this page
// core components
import EditorComponent from "./EditorComponent";
import EndProcessComponent from "./EndProcessComponent";
import TabContents, {Tab} from "../Shared/TabContents";
import {Column} from "../Shared/CustomTableComponent/CustomTableComponent";
import useTranslate from "../../hooks/useTranslate";
import {Query} from "../Shared/QueryComponent/QueryComponent";
import {State} from "../../types/State";
import {emailDataToSend} from "../../services/backend/EmailService";
import TableData from "./TableData";

export const EmailContext = createContext<{
  from?: string[];
  data: any[];
  setData: (data: any[]) => void;
  oldData: { data?: any[], columns?: any[], totalData?: number, page?: number };
  setOldData: (data: { data?: any[], columns?: any[], totalData?: number, page?: number }) => void;
  setColumns: (data: any[]) => void;
  columns: Column[];
  setAllColumns: (data: any[]) => void;
  allColumns: Column[];
  emailEditorRef?: { current: any },
  query?: Query;
  setQuery?: React.Dispatch<React.SetStateAction<Query | undefined>>
  page?: number | false;
  totalData?: number;
  setPage?: (p?: number | false) => void;
  loading?: State;
  setLoading?: (p: State) => void;
  setTotalData?: (p?: number) => void;
  onFinish?: (c: Column[], all: Column[], p?: number) => void;
}>({
  from: [],
  setData: () => {
  },
  setColumns: () => {
  },
  data: [],
  oldData: {},
  setOldData: () => {
  },
  columns: [],
  emailEditorRef: {current: null},
  setQuery: () => {
  },
  setPage: () => {
  },
  page: 0,
  allColumns: [],
  setAllColumns(data: any[]): void {
  }

});

const EmailTemplateComponents = (props: { from: string[], [key: string]: any }) => {

  const {t} = useTranslate('email-blast');

  const [columns, setColumns] = useState<Column[]>([]);
  const [allColumns, setAllColumns] = useState<Column[]>([]);
  const [page, setPage] = useState<number | false>();
  const [totalData, setTotalData] = useState<number>();
  const [loading, setLoading] = useState(State.IDLE);
  const [query, setQuery] = useState<Query | undefined>();
  const [data, setData] = useState<any[]>([]);
  const [oldData, setOldData] = useState<{ data?: any[], columns?: any[], totalData?: number, page?: number }>({});
  const emailEditorRef = useRef(null);


  const onFinish = async (c: Column[], all: Column[], p?: number) => {
    setColumns(c);
    setAllColumns(all);
    if (setLoading) {
      setLoading(State.PENDING)
      if (query && c.length) {
        if (p && p !== page && setPage) {
          setPage(p);
        }
        const res = await emailDataToSend(all, p ?? 0, query)
        if (res.success && setTotalData) {
          const {total_items, items} = res;
          setData(items);
          setTotalData(total_items as number);
        }
      }
      setLoading(State.RESOLVED);
    }
  }
  const tabs: Tab[] = [
    {
      component: <TableData/>,
      label: t("tab-1")
    },
    {
      component: <EditorComponent
        loadMergeTags={{setQuery, query, visible: false}}
        columns={columns}
        emailEditorRef={emailEditorRef}/>,
      label: t("tab-2"),
      disabled: !data.length
    },
    {
      component: <EndProcessComponent/>,
      label: t("tab-3"),
      disabled: !data.length
    },
  ]

  return (
    <>
      <EmailContext.Provider
        value={{
          setTotalData,
          totalData,
          setPage,
          page,
          loading,
          setLoading,
          from: props.from,
          setColumns,
          columns,
          emailEditorRef,
          data,
          setData,
          oldData,
          setOldData,
          query,
          setQuery,
          onFinish,
          allColumns,
          setAllColumns
        }}>
        <TabContents reloadInfo={false} tabs={tabs}/>
      </EmailContext.Provider>
    </>

  )
}

export default EmailTemplateComponents;
