import Axios, { AxiosResponse} from "axios";
import { Response } from "../types/Response";
import * as Sentry from "@sentry/nextjs";

const http = Axios.create({
  headers: {
    authorization: `Bearer ${setAuthtoken()}`,
  },
});

export function setHeaderToken(token: string) {
  localStorage.setItem("crm_token", token);
  http.defaults.headers = { Authorization: "Bearer " + token };
}

function setAuthtoken() {
  if (typeof localStorage !== "undefined") {
    if(localStorage.getItem("main_users")){
      return JSON.parse(localStorage.getItem("main_users")!)[0];
    }
    return localStorage.getItem("crm_token") ?? "";
  }
  return "";
}

http.interceptors.response.use(
  function (response: AxiosResponse) {
    return response;
  },
  (error) => {
    console.error(error);
  },
);


// http.interceptors.request.use(
//     function (request: AxiosRequestConfig) {
//       console.log('request', request)
//       return request;
//     },
//     (error) => {
//       console.error(error);
//     },
// );

export function get(url: string, params?: any, headers?: any, cancelToken?: any): Promise<Response> {
  return http
    .get(url, { params, headers, cancelToken })
    .then((res) => res.data)
    .catch((err) => {
      Sentry.captureException(err);
      return Promise.resolve({ success: false });
    });
}

export async function post<R = any>(
  url: string,
  params?: any,
  queryParams?: any,
  timeout?: number,
): Promise<Response<R>> {
  try {
    const res = await http.post(url, params, {
      params: queryParams,
      timeout,
      timeoutErrorMessage: "request timeout!",
    });
    return res?.data ? res.data : res;
  } catch (reason) {
    Sentry.captureException(reason);
    return await Promise.reject(reason);
  }
}

export function put(url: string, params?: any): Promise<Response> {
  return http
    .put(url, params)
    .then((res) => {
      return (res.data ? res.data : res) ?? {};
    })
    .catch((reason) => {
      Sentry.captureException(reason);
      return Promise.reject(reason);
    });
}

export function deleteReq(url: string, params?: any): Promise<Response> {
  return http
    .delete(url, params)
    .then((res) => res.data)
    .catch((reason) => {
      Sentry.captureException(reason);
      console.error(reason.message);
    });
}
