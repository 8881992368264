import React, { useEffect, useMemo, useState } from "react";
import SideBar from "../../LayoutComponenents/SideBar";
import routes, {
  mappingRoutes,
  ViewFormat,
  ViewProps,
} from "../../../routes/routes";
import {
  SubMenuItem,
  SubMenuProps,
} from "../../LayoutComponenents/types/SideBar";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/Reducers";
import useTranslate from "../../../hooks/useTranslate";
import useIgnoreFirstEffect from "../../../hooks/useIgnoreFirstEffect";
import HeadDynamicComponent from "./HeadDynamicComponent";
import { routerActions } from "../../../store/RouterReducer";

type Props = {
  children: any;
  sidebarOpen: boolean;
};

export type TabsNames = "data" | "proceedings";
export type TabsNamesArray = "bulk_updates";

export const tabsRegisteredName: (TabsNames | TabsNamesArray)[] = [
  "data",
  "proceedings",
  "bulk_updates",
];

const SideBarContent = ({ children, sidebarOpen }: Props) => {
  const history = useRouter();
  const { t } = useTranslate("routes");
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState("");
  const {
    user: { permissionsMapper, config, domains, permissions, role },
  } = useSelector((state: RootState) => state.AuthReducer);

  const userRoutes = useMemo(() => {
    if (config) {
      return mappingRoutes(config, permissions, permissionsMapper, domains, t);
    }
    return [];
  }, [config, permissionsMapper, permissions, domains]);

  useEffect(() => {
    dispatch(routerActions.setCurrentRoutes(userRoutes));
  }, [userRoutes, dispatch]);

  useIgnoreFirstEffect(() => {
    const current = userRoutes.find(
      (r) =>
        r.views?.find((v) => {
          const path = `${v.root}${v.path !== "/" ? v.path : ""}`;
          return history.pathname.split("?").shift() === path;
        }),
    );
    setExpanded(current?.name ?? routes[0].name);
  }, [userRoutes, history.pathname]);

  const getPath = (view: ViewProps) =>
    `${view.root}${view.path !== "/" ? view.path : ""}`;

  const getRouteLabelByRole = (route: ViewFormat) => {
    if (
      role &&
      route.config_by_role &&
      route.config_by_role[role.toUpperCase()]
    ) {
      return (
        route.config_by_role[role.toUpperCase()]?.label ?? route.label ?? ""
      );
    }

    return route.label ?? "";
  };

  const getRouteHideByRole = (route: ViewFormat) => {
    if (
      role &&
      route.config_by_role &&
      route.config_by_role[role.toUpperCase()]
    ) {
      return route.config_by_role[role.toUpperCase()]?.hide ?? false;
    }

    return false;
  };

  return (
    <>
      <div className="app-main">
        <SideBar
          sidebarOpen={sidebarOpen}
          menu={[
            {
              label: "Menu",
              items: userRoutes.map((route) => {
                return {
                  name: route.label ?? route.name,
                  active: expanded === route.name,
                  icon: route.icon,
                  path: route.path,
                  label: route.label ?? t(route.name),
                  subItems:
                    route.views
                      ?.filter(
                        (subItem) =>
                          !subItem.hide && !getRouteHideByRole(subItem),
                      )
                      .map((subItem) => {
                        const path = getPath(subItem);
                        const sub: SubMenuProps = {
                          active: history.pathname.split("?").shift() === path,
                          path: path,
                          label: getRouteLabelByRole({
                            ...subItem,
                            label: subItem.label ?? t(subItem.name),
                          }),
                          name: subItem.label ?? subItem.name,
                          subItems: subItem.views?.map((subItemI) => {
                            const subPath = getPath(subItemI);
                            const subI: SubMenuItem = {
                              name: subItemI.label ?? subItemI.name,
                              // label: subItemI.label ?? t(subItemI.name),
                              label: getRouteLabelByRole({
                                ...subItemI,
                                label: subItemI.label ?? t(subItemI.name),
                              }),
                              path: subPath,
                              active:
                                history.pathname.split("?").shift() === subPath,
                            };
                            return subI;
                          }),
                        };
                        return sub;
                      }) ?? [],
                };
              }),
            },
          ]}
        />
        <div className="app-main__outer overflow-auto">
          <div className="app-main__inner">
            <HeadDynamicComponent />
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBarContent;
