import fetchJson from "../../lib/fetchJson";
import {Email} from "../../types/Email";
import {get, post, put} from "../../network";
import {Response} from "../../types/Response";
import {Template} from "../../types/Template";
import {AnyData} from "../../types/AnyData";
import {Column} from "../../components/Shared/CustomTableComponent/CustomTableComponent";
import {findByAll, findByModel} from "./SbxService";
import {DEFAULT_SIZE, toJSON} from "../../utils";
import {Query} from "../../components/Shared/QueryComponent/QueryComponent";
import {TrackEventMonitor} from "../../classes/TrackEvent";
import {sizeToMaxPage} from "../../components/EmailTemplateComponents/EndProcessComponent";
import {SbxModelField} from "../../types/Sbx";

const sbxConfigService: string = process.env.SBX_SVC_CONFIG!
const domainId: string = process.env.SBX_DOMAIN!
const appKey: string = process.env.SBX_APP_KEY!

export const getFromList = async (token: string) => {

  if (!token.toLowerCase().startsWith("bearer")) {
    token = `Bearer ${token}`;
  }

  try {

    console.log({
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'Content-Type': 'application/json',
        "App-Key": appKey,
        "Authorization": token
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    })

    return await fetchJson(`${sbxConfigService}/api/v2/${domainId}/email_config`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'Content-Type': 'application/json',
        "App-Key": appKey,
        "Authorization": token
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    })

  } catch (error: any) {
    console.error(error);
    return {success: false, error: error.message};
  }


}


export const sendEmail = async (body: Email) => {
  const res = await post("/api/v2/mail/send", body);
  if (res?.success) {
    await TrackEventMonitor.newEvent({
      metadata: {
        name: "sbx_crm_email_sent"
      },
      props: {
        email_type: "blast",
        to: body.to,
        from: body.from,
        sms_key: res.item,
        template: body.template,
        data: body.data
      }
    })
    return res;
  }
  return {success: false}
}

export async function checkStatusService(keys: string[]): Promise<Response<{
  domain_id: number;
  id: number;
  key: string;
  response: string;
}>> {
  return post("/api/v2/mail/status", keys)
    .then(res => {
      if (res.success && res.items?.length) {
        res.items = res.items.map(e => ({...e, response: toJSON(e.response)}))
      }
      return res;
    })
};


export function transformData(html: string, data: any, columnsValue: AnyData) {
  const newData = {...data};
  if (newData.all_data && Object.values(columnsValue).length) {
    let allData = [...data.all_data];

    newData.all_data = allData.map((val: any) => {

      let newVal: any = {}

      Object.keys(val).forEach(key => {
        if (columnsValue[key]) {
          newVal[key] = val[key];
        }
      });
      return newVal;
    })
  }
  Object.keys(newData).forEach(key => {
    if (key.includes("-")) {
      const newKey = key.replaceAll("-", "_");
      html = html.replaceAll(key, newKey);
      newData[newKey] = data[key];
    }
  });

  return {data: newData, template: html}
}

export function transformRow(data: any){
  let newData = {...data}
  Object.keys(newData).forEach(key => {
    if (key.includes("-")) {
      const newKey = key.replaceAll("-", "_");
      newData[newKey] = data[key];
    }
  });

  return newData;
}

export async function sendBulkEmailService(
  bodyEmail: AnyData,
  items: any[],
  html: string,
  columnsValue: AnyData,
  progress: (progress: number, res: { item: any, res: any }) => void,
  disrupted: () => void,
  sent: any[],
  config: { test?: { email: string; } },
  pagination?: { page: number }
) {
  const result: any[] = [];
  let i = sent.length ? (
    pagination ? (sent.slice(
      (pagination.page) * sizeToMaxPage,
      (pagination.page) * sizeToMaxPage + sizeToMaxPage)).length - 1 :
      (sent.length - 1)
  ) : 0;
  while (i < items.length) {
    try {
      const item = items[i] ?? {};
      const {bcc, cc, from, subject, field} = bodyEmail;
      const {data = {}, template} = transformData(html, item, columnsValue);
      let res;
      const sentEmail = sent.some(e => e[field] === item[field]);
      if (!sentEmail) {
        res = await sendEmail({
          data,
          bcc: config.test ? [] : bcc,
          cc: config.test ? [] : cc,
          from,
          subject,
          to: config.test ? (data[field] ? [config.test.email] : [null]) : [data[field]],
          template
        });
      } else {
        res = {success: true, already: true}
      }
      if (!res?.success) {
        disrupted();
        i = items.length;
      } else {
        if (!res.already) {
          progress(i, {item, res});
        }
        i++;
      }
    } catch (e) {
      i++;
    }
  }
  return result;
}

export async function emailDataToSend(c: Column[], p: number, query: Query, props?: {
  size?: number;
  allData?: boolean
}) {
  const references = c.filter(c => c.name.includes("-"));

  const service = props?.allData ? findByAll : findByModel;

  const res = await service({
    ...{
      ...query,
      fetch: c.reduce((c: string[], p) => {
        function pushToC(value: string) {
          if (!c.find(crr => crr === value)) {
            c.push(value);
          }
        }


        if (p.type === SbxModelField.REFERENCE) {
          const fetch = p.name.split("-");
          let acm = "";
          fetch.forEach(str => {
            if (acm) {
              acm += `.${str}`;
              pushToC(acm);
            } else {
              acm = str;
              pushToC(acm);
            }
          });
        }
        return c;
      }, []),
    },
    size: props?.size ?? DEFAULT_SIZE,
    page: p
  });
  if (res.success) {
    const {fetched_results} = res;

    const ref = Object.keys(fetched_results ?? {}).reduce((obj: AnyData, key) => {
      obj = {...obj, ...fetched_results[key]};
      return obj;
    }, {});

    if (references.length && fetched_results) {
      res.items = res.items.map((row: any) => {
        return {
          ...row,
          ...references.reduce((obj: any, column) => {
            const [field, reference1, reference] = column.name.split("-").reverse();
            const keyRef = reference ? (ref[(row[reference] ?? "")] ?? {})[reference1] : row[reference1];
            if (ref && keyRef) {
              const data = ref[keyRef];
              if (data) {
                obj[column.name] = data[field];
              }
            }
            return obj;
          }, {})
        }
      })
    }
    return res;
  } else {
    return {success: false}
  }
}

export const getTemplate = async (body: { template: string, data: any }): Promise<{ success: boolean, item: any }> => {
  try {
    const header = {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(body)
    }

    return await fetchJson("/api/v2/template/ejs", header)
  } catch (e: any) {
    return Promise.resolve({success: false, item: e.message})
  }
}


export function getTemplates(): Promise<Response<Template>> {
  return get("/api/v2/template");
}

export function saveTemplate(template: Template): Promise<Response> {
  return post("/api/v2/template", template);
}

export function updateTemplate(template: Template): Promise<Response> {
  return put("/api/v2/template", template);
}


