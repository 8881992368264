import * as React from 'react';
import Image from "next/image";

const chatbot = `https://demo.sbx.services/sbx-server/www/fempha/ai-agent/images/bot_icon.png`;
type Props = {
    helperButton?: React.ReactElement
};

const ChatBotHeaderComponent = ({helperButton}: Props) => {

    return (
        <div
            className="border-bottom py-1 rounded-top px-4 text-white" style={{
            background: "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(222,235,250,1) 72%, rgba(0,103,218,1) 100%, rgba(0,212,255,1) 100%)"
        }}>
            <div className="d-flex align-items-center justify-content-between mt-1">
                <div className="d-flex align-items-center gap-2">
                    <Image
                        priority
                        src={chatbot}
                        height={35}
                        width={35}
                        alt="Follow us on Twitter"
                    />

                    <div className="d-flex align-items-center gap-1">
                        <span className="bg-success rounded-circle" style={{width: '7px', height: '7px'}}/>
                        <span className="font-xs text-primary">
                            SBX AI
                        </span>
                    </div>
                </div>

                {helperButton ?? null}
            </div>


        </div>
    );
};

export default ChatBotHeaderComponent