import React, { useCallback, useMemo, useState } from "react";
import { AnyData } from "../../types/AnyData";
import { Response } from "../../types/Response";
import useAsyncEffect from "../../hooks/useAsyncEffect";
import {
  checkStatusService,
  transformRow,
} from "../../services/backend/EmailService";
import { useDispatch } from "react-redux";
import { actionsModal, ModalTypes } from "../../store/Modal/Slice";
import CustomTableComponent from "../Shared/CustomTableComponent/CustomTableComponent";
import { toMap } from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCheckCircle,
  faClock,
  faListAlt,
  faRefresh,
  faTimes,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "reactstrap";

interface IProps {
  data: { item: AnyData; res: Response }[];
  field: string;
}

const EmailSentComponent = ({ data, field }: IProps) => {
  const dispatch = useDispatch();
  const [loading, onLoading] = useState(false);
  const [state, setState] = useState<"pending" | "success" | "error">();

  const [response, setResponse] = useState<{
    [key: string]: {
      domain_id: number;
      id: number;
      key: string;
      response: Response;
    };
  }>();

  const service = useCallback(async () => {
    onLoading(true);
    const keys = data.map((d) => d.res.item);
    if (keys.length) {
      const res = await checkStatusService(keys);
      if (res.success) {
        setResponse(toMap(res.items ?? [], "key") as any);
      }
    } else {
      dispatch(
        actionsModal.closeModal({ type: ModalTypes.DYNAMIC_COMPONENT_MODAL }),
      );
    }
    onLoading(false);
  }, [data]);

  useAsyncEffect(service, [service]);

  const rows = useMemo(() => {
    if (response) {
      return data.reduce((row: any[], d) => {
        const check = response[d.res.item]?.response?.success;
        const _d = transformRow(d.item);
        const data = {
          email: _d[field],
          message:
            check !== undefined
              ? response[d.res.item]?.response?.message ?? "Sent"
              : "Pending",
          statusData: check,
          status: (
            <FontAwesomeIcon
              className={check ? "text-success" : "text-danger"}
              icon={check ? faCheckCircle : faTimesCircle}
            />
          ),
        };

        switch (state) {
          case "success":
            if (check) {
              row.push(data);
            }
            break;

          case "error":
            if (check === false) {
              row.push(data);
            }
            break;

          case "pending":
            if (check === undefined) {
              row.push(data);
            }
            break;
          default:
            row.push(data);
            break;
        }

        return row;
      }, []);
    }
    return [];
  }, [response, data, field, state]);

  return (
    <div>
      <div className="d-flex justify-content-between">
        <div>
          <Button
            onClick={() => setState(undefined)}
            size="sm"
            color="primary"
            className="me-1"
          >
            <FontAwesomeIcon icon={faListAlt} /> All
          </Button>
          <Button
            onClick={() => setState("success")}
            size="sm"
            color="success"
            className="me-1"
          >
            <FontAwesomeIcon icon={faCheck} /> Good
          </Button>
          <Button
            onClick={() => setState("pending")}
            size="sm"
            color="warning"
            className="me-1"
          >
            <FontAwesomeIcon icon={faClock} /> Pending
          </Button>
          <Button
            onClick={() => setState("error")}
            size="sm"
            color="danger"
            className="me-1"
          >
            <FontAwesomeIcon icon={faTimes} /> Errors
          </Button>
        </div>
        <Button disabled={loading} color="primary" size="sm" onClick={service}>
          <FontAwesomeIcon spin={loading} icon={faRefresh} /> Refresh
        </Button>
      </div>
      <CustomTableComponent
        useLocalPage
        localFilter
        exportButtons={[{ type: "csv", color: "info" }]}
        columns={[
          { name: "email", header: "Email" },
          { name: "message", header: "Message" },
          {
            name: "status",
            header: "Status",
            data: "statusData",
            type: "ReactNode",
            className: "text-center",
            headerClassName: "text-center",
          },
        ]}
        data={rows}
      />
    </div>
  );
};

export default EmailSentComponent;
