import {Col, FormGroup, Label, Row,} from "reactstrap";
import {InputTags} from 'react-bootstrap-tagsinput';
import 'react-bootstrap-tagsinput/dist/index.css';
import {useContext, useEffect, useState} from "react";
import {EmailContext} from "./index";
import Select from "react-select"
import useTranslate from "../../hooks/useTranslate";
import {useSelector} from "react-redux";
import {RootState} from "../../store/Reducers";
import {Column} from "../Shared/CustomTableComponent/CustomTableComponent";

const BodyEmailInputs = ({onChange, columns}:
                           {
                             onChange: (data: {
                               bcc: string[],
                               cc: string[], subject: string, from: string, field: string
                             }) => void,
                             columns: Column[]
                           }) => {

  const {t} = useTranslate("email-blast");
  const {user: {config}} = useSelector((root: RootState) => root.AuthReducer)

  const {from} = useContext(EmailContext);
  const [bcc, setBcc] = useState<string[]>([])
  const [cc, setCC] = useState<string[]>([])
  const [subject, setSubject] = useState<string>("");
  const [fromValue, setFromValue] = useState<any>(null);
  const [field, setField] = useState<any>(null);

  useEffect(() => {
    onChange({bcc, subject, cc, from: fromValue?.value ?? "", field: field?.value ?? ""})
  }, [bcc, cc, subject, from, field])

  useEffect(() => {
    if (from?.length) {
      setFromValue({value: from[0], label: from[0]})
    }
  }, [from])

  useEffect(() => {
    if (config?.sbx_crm?.extra_setup?.communications?.default_bcc) {
      setBcc(config?.sbx_crm?.extra_setup?.communications?.default_bcc);
    }
  }, [config])

  useEffect(() => {
    if (columns.length && !field) {
      const fieldEmail = columns.find(cl => cl.name?.includes("email"));
      if (fieldEmail) {
        setField({value: fieldEmail.name.replaceAll("-", "_"), label: fieldEmail.header});
      }
    }
  }, [columns, field])

  return (
    <div className="card mb-2">
      <div className="card-body">
        <Row>
          <Col md={6}>
            <FormGroup className="height-content-auto">
              <Label>BCC</Label>
              <InputTags values={bcc} onTags={(value) => setBcc(value.values)}/>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup className="height-content-auto">
              <Label>CC</Label>
              <InputTags values={cc} onTags={(value) => setCC(value.values)}/>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>{t("from")}</Label>
              <Select
                id="sle"
                name="sle"
                value={fromValue}
                onChange={setFromValue}
                options={from && from.map(e => ({label: e, value: e}))}/>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>{t("field-email")}</Label>
              <Select
                id="sle1"
                name="sle1"
                value={field}
                onChange={setField}
                options={columns.map(e => ({label: e.header, value: e.name.replaceAll("-", "_")}))}/>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{t("subject")}</Label>
              <input required className="form-control" value={subject} onChange={e => setSubject(e.target.value)}/>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default BodyEmailInputs;
