import {cloudScriptRun} from "./SbxService";
import {Response} from "../../types/Response";
import {getAllUsers} from "./PermissionsService";
import {UserData} from "../../types/User";
import {recursiveChart, toast} from '../../utils';
import {get} from "../../network";
import {UserRoleBudget} from "../../types/Chart";
import {bulkImportService} from "./importService";


export const getChartData = async (params: { tag: string, initial_date?: string, final_date?: string }) => {
  const res: Response = await cloudScriptRun({params: {...params, type: "chart"}});

  if (res.success) {
    let ids: string[] = [];
    recursiveChart(res.item, {dep: (chart) => ids.push(chart.user_id.toString())});
    const resUsers = await getAllUsers({ids: ids.join(",")});
    res.item = recursiveChart(res.item,
      {
        dep: (chart => resUsers.items?.find((user: UserData) => user.id === chart.user_id)),
        update: {field: "user"}
      });
    return res;
  } else {
    return res;
  }
}

export const BulkUpdateData = async (params: { type?: "cs" | "migrate_users" | "migrate_memberships", key?: string, params: {[data:string]:any}, queryParams?: {beforeKey?: string, afterKey?: string} }) => {
  try {

    let res: Response | null = null


    let bulkImportTypes = ["migrate_users", "migrate_memberships"]
    if (params.type && bulkImportTypes.includes(params.type)){
      res = await bulkImportService( params.type ,params.params.hasOwnProperty("data") ? params.params.data : params.params, params.queryParams);
    }else{
        delete params.type
        res = await cloudScriptRun(params);
    }
    return res

  } catch (error) {
    console.error(error);
    toast({type: "error", message: "Upload fail"});
    return {success: false, error};
  }
}


export const updateChartBudget = (budget: UserRoleBudget | UserRoleBudget[]) => {
  return cloudScriptRun({
    params: {
      model: "sbx_crm_user_role_budget",
      body: budget,
      type: "update"
    }
  })
}

export const createChartBudget = (budget: UserRoleBudget | UserRoleBudget[]) => {
  return cloudScriptRun({
    params: {
      model: "sbx_crm_user_role_budget",
      body: budget,
      type: "create"
    }
  })
}

export const getUsersById = (ids: string) => {
  return get("/api/v2/security/auth/users", {ids})
}
