import React, { useContext, useEffect, useState } from "react";
import EmailEditor, { Design } from "react-email-editor";
import SelectTemplateModal from "../Shared/Modal/SelectTemplateModal";
import { Template } from "../../types/Template";
import SaveTemplateModal from "../Shared/Modal/SaveTemplateModal";
import {
  saveTemplate,
  updateTemplate,
} from "../../services/backend/EmailService";
import {
  capitalize,
  downloadTextToFile,
  getHtmlFromEditor,
  JSONtoString,
  replaceValues,
  saveDesignFromEditor,
  toast,
  toJSON,
} from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faCirclePlus,
  faCopy,
  faDatabase,
  faFileExport,
  faPlus,
  faPlusCircle,
  faSave,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import useIgnoreFirstEffect from "../../hooks/useIgnoreFirstEffect";
import { useDispatch } from "react-redux";
import { actionsModal, ModalTypes } from "../../store/Modal/Slice";
import { Button, Card } from "reactstrap";
import useTranslate from "../../hooks/useTranslate";
import { Column } from "../Shared/CustomTableComponent/CustomTableComponent";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { Query } from "../Shared/QueryComponent/QueryComponent";
import { EmailContext } from "./index";

type Props = {
  onlyView?: boolean;
  columns: Column[];
  emailEditorRef: any;
  loadMergeTags?: {
    visible: boolean;
    action?: () => void;
    label?: string;
    icon?: IconDefinition;
    query?: Query;
    setQuery: (query: Query) => void;
  };
};

const EditorComponent = ({
  onlyView,
  loadMergeTags,
  columns,
  emailEditorRef: refEditor,
}: Props) => {
  const { query } = useContext(EmailContext);
  // const [downloadLink, setDownloadClick] = useState(null);
  const [open, setOpen] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState<null | Template>(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [otherTags, setOtherTags] = useState<
    Record<"name" | "value", string>[]
  >([]);

  const { t } = useTranslate("email-blast");

  const onLoad = (design?: Design) => {
    if (design && refEditor && refEditor.current) {
      refEditor.current.editor.loadDesign(design);
      setOpen(false);
    }
  };

  function setTags() {
    dispatch(
      actionsModal.openModal({
        type: ModalTypes.DYNAMIC_COMPONENT_MODAL,
        size: "md",
        title: "Set new tag",
        typeForm: "submit",
        form: "setTag",
        toggle: () =>
          dispatch(
            actionsModal.closeModal({
              type: ModalTypes.DYNAMIC_COMPONENT_MODAL,
            }),
          ),

        value: { name: "", value: "" },
        renderComponent: (value, onChangeValue) => {
          return (
            <form
              id={"setTag"}
              onSubmit={(e) => {
                e.preventDefault();
                setOtherTags((t) => [...t, value]);
                dispatch(
                  actionsModal.closeModal({
                    type: ModalTypes.DYNAMIC_COMPONENT_MODAL,
                  }),
                );
              }}
            >
              <div className="form-group-sm">
                <label htmlFor="Name">Name</label>
                <input
                  required
                  id={"Name"}
                  placeholder="Name"
                  value={value.name}
                  type="text"
                  className="form-control"
                  onChange={(e) =>
                    onChangeValue((v: any) => ({ ...v, name: e.target.value }))
                  }
                  name={"Name"}
                />
              </div>

              <div className="form-group-sm">
                <label htmlFor="Value">Value</label>
                <input
                  required
                  placeholder="Value key"
                  id={"Value"}
                  value={value.value}
                  type="text"
                  className="form-control"
                  onChange={(e) =>
                    onChangeValue((v: any) => ({ ...v, value: e.target.value }))
                  }
                  name={"Value"}
                />
              </div>
            </form>
          );
        },
      }),
    );
  }

  function onLoading() {
    setLoading((e) => !e);
  }

  async function onSave(name: string) {
    onLoading();
    const bodyJson: any = await saveDesignFromEditor(refEditor);
    const htmlR = await getHtmlFromEditor(refEditor);
    const bodyHtml = replaceValues(htmlR);
    delete bodyJson.schemaVersion;
    const temp = {
      name,
      body: JSON.stringify(bodyJson),
      body_html: bodyHtml,
      tags: "",
      query: loadMergeTags?.query
        ? JSONtoString(loadMergeTags?.query)
        : undefined,
    };
    const res = await saveTemplate(temp);
    if (res.success) {
      setOpenNew(false);
      setCurrentTemplate({ ...temp, _KEY: res.item });
      toast({ type: "success", message: t("common:created") });
    } else {
      toast({ type: "error", message: "" });
    }
    onLoading();
  }

  useIgnoreFirstEffect(() => {
    if (currentTemplate) {
      const body: Design = toJSON(currentTemplate.body);
      onLoad(body);
      if (loadMergeTags && !Boolean(query)) {
        const str = currentTemplate.query as string;
        loadMergeTags.setQuery(toJSON(str) as Query);
      }
    }
  }, [currentTemplate]);

  async function onUpdate() {
    onLoading();
    dispatch(actionsModal.closeModal({ type: ModalTypes.CONFIRM }));
    let newTemplate: any = await saveDesignFromEditor(refEditor);
    const htmlR = await getHtmlFromEditor(refEditor);
    const bodyHtml = replaceValues(htmlR);
    delete newTemplate.schemaVersion;
    let newTemplateData: any = Object.assign({}, currentTemplate);
    delete newTemplateData._META;
    const res = await updateTemplate({
      ...newTemplateData,
      body: JSON.stringify(newTemplate),
      body_html: bodyHtml,
      query: loadMergeTags?.query
        ? JSONtoString(loadMergeTags?.query)
        : currentTemplate?.query,
    });
    if (res.success) {
      toast({ type: "success", message: t("common:updated") });
    } else {
      toast({ type: "error", message: "" });
    }
    onLoading();
  }

  async function onExport() {
    const htmlR = await getHtmlFromEditor(refEditor);
    const html = replaceValues(htmlR);
    downloadTextToFile(html, "html", currentTemplate?.name);
    dispatch(actionsModal.closeModal({ type: ModalTypes.CONFIRM }));
  }

  function onConfirmationUpdate() {
    dispatch(
      actionsModal.openModal({
        type: ModalTypes.CONFIRM,
        onConfirm: onUpdate,
        message: (
          <p>
            ¿{t("warn-to-update-template")} <b>{currentTemplate?.name}</b>?
          </p>
        ),
        title: (
          <span>
            <FontAwesomeIcon className="me-2" icon={faCopy} />
            {t("common:update")}{" "}
          </span>
        ),
      }),
    );
  }

  function exportHtml() {
    dispatch(
      actionsModal.openModal({
        type: ModalTypes.CONFIRM,
        onConfirm: onExport,
        message: (
          <p>
            ¿{t("warn-to-export")}{" "}
            <b>{currentTemplate?.name || "unassigned"}</b>?
          </p>
        ),
        title: (
          <span>
            <FontAwesomeIcon className="me-2" icon={faFileExport} />
            {t("common:export")}
          </span>
        ),
      }),
    );
  }

  return (
    <>
      <Card>
        <div className="d-flex justify-content-between p-4">
          <h4>{currentTemplate?.name || "unassigned"}</h4>
          <div>
            {loadMergeTags?.visible && (
              <Button color="primary" size="sm" onClick={loadMergeTags.action}>
                {loadMergeTags.icon && (
                  <FontAwesomeIcon icon={loadMergeTags.icon} />
                )}{" "}
                {loadMergeTags.label}
              </Button>
            )}
            <button
              disabled={loading}
              title={t("set new tag")}
              className="btn btn-primary btn-sm ms-1"
              onClick={setTags}
            >
              <FontAwesomeIcon icon={loading ? faSpinner : faCirclePlus} />
              {" " + t("Set new tag")}
            </button>
            <button
              disabled={loading}
              title={t("export-html")}
              className="btn btn-primary btn-sm ms-1"
              onClick={exportHtml}
            >
              <FontAwesomeIcon
                spin={loading}
                icon={loading ? faSpinner : faFileExport}
              />
              {" " + t("export-html")}
            </button>
            <button
              disabled={loading}
              title={t("load-template")}
              className="btn btn-primary btn-sm ms-1"
              onClick={() => setOpen(true)}
            >
              <FontAwesomeIcon icon={loading ? faSpinner : faDatabase} />
              {" " + t("load-template")}
            </button>
            {currentTemplate && (
              <button
                disabled={loading}
                title={t("save-as")}
                className="btn btn-primary btn-sm ms-1"
                onClick={() => setOpenNew(true)}
              >
                <FontAwesomeIcon icon={loading ? faSpinner : faPlusCircle} />
                {" " + t("common:save-as")}
              </button>
            )}
            <button
              disabled={loading}
              title={
                currentTemplate ? t("update-template") : t("save-template")
              }
              className="btn btn-primary btn-sm ms-1"
              onClick={() =>
                currentTemplate ? onConfirmationUpdate() : setOpenNew(true)
              }
            >
              <FontAwesomeIcon
                icon={loading ? faSpinner : currentTemplate ? faSave : faPlus}
              />
              {" " +
                (currentTemplate ? t("update-template") : t("save-template"))}
            </button>
          </div>
        </div>
      </Card>

      <Editor
        onlyView={onlyView}
        refEditor={refEditor}
        onLoad={onLoad}
        columns={columns}
        mergeTags={otherTags}
      />

      <SelectTemplateModal
        onSelected={setCurrentTemplate}
        open={open}
        toggle={setOpen}
      />
      <SaveTemplateModal
        loading={loading}
        onSuccess={onSave}
        open={openNew}
        toggle={setOpenNew}
      />
    </>
  );
};

const Editor = ({
  refEditor,
  onLoad,
  columns,
  onlyView,
  mergeTags,
}: {
  refEditor: {
    current: any;
  };
  onlyView?: boolean;
  onLoad: () => void;
  columns?: any[];
  mergeTags?: Record<"name" | "value", string>[];
}) => {
  function getName(name: string, key: string) {
    return name.includes(" ")
      ? `<%= ${key}["${name.replaceAll("-", "_")}"] %>`
      : `<%= ${key}.${name.replaceAll("-", "_")} %>`;
  }

  function getMergeTag() {
    function getTagValue(column: Column) {
      switch (column.type) {
        case "ArrayObject":
          return `
                <div style="overflow-x:auto;max-width: 600px;">
                  @tableAll(data.${column.name});
                </div>
               `;
        case "Array":
          return `
                <div>
                  @table(data.${column.name});
                </div>
               `;
        default:
          return getName(column.name, "data");
      }
    }

    return {
      mergeTags: columns?.map((column) => ({
        name: capitalize(column.header)
          .replaceAll("-", " ")
          .replaceAll("_", " "),
        value: getTagValue(column),
      })),
    };
  }

  useIgnoreFirstEffect(() => {
    if (refEditor && refEditor.current && columns?.length) {
      refEditor.current.setMergeTags(getMergeTag().mergeTags);
    }
  }, [columns]);

  useEffect(() => {
    if (refEditor && refEditor.current && mergeTags?.length) {
      refEditor.current.setMergeTags([
        ...(getMergeTag().mergeTags ?? []),
        ...(mergeTags ?? []).map((tag) => ({
          ...tag,
          value: getName(tag.name, "data"),
        })),
      ]);
    }
  }, [mergeTags, refEditor]);

  return (
    <EmailEditor
      style={{ height: `calc(100vh - ${onlyView ? "220px" : "280px"})` }}
      options={{
        id: "editor",
        mergeTags: [
          ...(getMergeTag().mergeTags ?? []),
          ...(mergeTags ?? []).map((tag) => ({
            ...tag,
            value: getName(tag.name, "data"),
          })),
        ],
      }}
      tools={{
        Video: {
          enabled: true,
        },
      }}
      ref={refEditor}
      onLoad={onLoad}
    />
  );
};

export default EditorComponent;
