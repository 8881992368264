import React from "react";
import { ProfileDesign } from "../../types/User";

interface IProps {
  noShadow?: boolean;
  type?: "basic" | "basic-doc",
  title?: string;
  items: {
    title?: string;
    body: React.ReactNode;
    timeLine?: string;
  }[];
  colors?: ProfileDesign;
}

const TimeLineComponent = ({title, items, noShadow, type = "basic-doc", colors}: IProps) => {

  function getType() {
    const defaultClass = "vertical-timeline vertical-timeline--animate vertical-timeline--one-column ";
    switch (type) {
      case "basic-doc":
        return defaultClass.concat("vertical-time-simple vertical-without-time")
      default:
        return defaultClass

    }
  }

  return (
    <div className={"main-card card " + (noShadow ? "shadow-none" : "")}>
      {colors ? <style>
        {`
          .vertical-timeline-element-icon {
            background: ${colors.company.timeline} !important; /* Color diferente cuando está activo */
          }
          .vertical-timeline::before {
            background: ${colors.company.timeline} !important; /* Color diferente cuando está activo */
          }
          .vertical-time-simple .vertical-timeline-element-icon::after {
            background: ${colors.company.timeline} !important; /* Color diferente cuando está activo */
          }
        `}
      </style> : ''}
      <div className="card-body" style={{background: colors?.company?.background}}>
        {title && <h5 className="card-title">{title}</h5>}
        <div className={getType()}>
          {
            items.map((item, index) => (
              <div key={item.title+"_"+index} className="vertical-timeline-item vertical-timeline-element">
                <div>
              <span className="vertical-timeline-element-icon bounce-in">
                <i className="badge badge-dot badge-dot-xl bg-success"/>
              </span>
                  <div className="vertical-timeline-element-content bounce-in">
                    {item.title && <h4 className="timeline-title mb-0">{item.title}</h4>}
                    {item.body}
                    {item.timeLine && <span className="vertical-timeline-element-date">{item.timeLine}</span>}
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}


export default TimeLineComponent;
