import ModalComponent from "./ModalComponent/ModalComponent";
import {FormGroup, Label} from "reactstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faSave, faSpinner, faTimes} from "@fortawesome/free-solid-svg-icons";
import {useRef} from "react";
import useTranslate from "../../../hooks/useTranslate";

interface Props {
  open: boolean;
  toggle: (data: boolean) => void;
  onSuccess: (name: string) => void;
  loading: boolean;
}

const SaveTemplateModal = ({onSuccess, toggle, open, loading}: Props) => {

  const name = useRef<any>(null);
  const {t} = useTranslate("email-blast");

  return (
    <ModalComponent
      title={<><FontAwesomeIcon icon={faSave}/> {" " + t("save-template")}</>}
      isOpen={open}
      toggle={() => toggle(false)}
      footer={(
        <>
          <button disabled={loading} className="btn btn-primary btn-sm" form="saveTemplate">
            <FontAwesomeIcon spin={loading} icon={loading ? faSpinner : faCheck}/>{" " + t("common:save")}
          </button>
          <button onClick={() => toggle(false)} className="btn btn-light btn-sm ms-1">
            <FontAwesomeIcon spin={loading} icon={loading ? faSpinner : faTimes}/>{" " + t("common:cancel")}
          </button>
        </>
      )
      }>
      <form
        id="saveTemplate"
        onSubmit={e => {
          e.preventDefault();
          if (name.current) {
            onSuccess(name.current.value);
          }
        }}>
        <FormGroup>
          <Label>{t("template-name")}</Label>
          <input disabled={loading} required className="form-control" type="text" ref={name}/>
        </FormGroup>
      </form>
    </ModalComponent>
  )
}

export default SaveTemplateModal;
