import React, { useMemo } from "react";
import ValidateFieldRequired from "../ValidateFieldRequired";
import { MenuPlacement, OptionsType, Styles, ValueType } from "react-select";
import { InputActionMeta } from "react-select/src/types";
import { NumberOption, StringOption } from "../../../types/Select";
import useTranslate from "../../../hooks/useTranslate";
import CreatableSelect from "react-select/creatable";

interface IProps {
  id: string;
  required?: boolean;
  name: string;
  value?: ValueType<any, any>;
  options: OptionsType<any>;
  invalid?: boolean;
  defaultValue?: ValueType<any, any>;
  readonly?: boolean;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  sortOptions?: boolean;
  loading?: boolean;
  noOptionsMessage?: string
  inputValue?: string
  isClearable?: boolean
  menuPosition?: 'absolute' | 'fixed'
  onChange?: (value: ValueType<any, any>) => void;
  menuPlacement?: MenuPlacement
  styles?:  Partial<Styles<any, false, any>>
  filterOption?: (option: StringOption | NumberOption, rawInput: string) => boolean;
  onInputChange?: (value: string, action: InputActionMeta) => void;
}

const MultiInputComponent = (props: IProps) => {
  const {
    id,
    name,
    value,
    onChange,
    className,
    options,
    placeholder,
    required,
    disabled,
    sortOptions = true,
    loading,
    inputValue,
    defaultValue, isClearable = false,
    onInputChange,
    noOptionsMessage,
    menuPlacement, styles,
    menuPosition, filterOption
  } = props;

  const {t} = useTranslate("common");

  const inputs = value;

  const opts = useMemo(() => {
    if (options && options.length > 0) {
      if (sortOptions) {
        return [...(options as { label: string, value: string }[])].filter(item => item.label).sort((a, b) => a.label.toString().localeCompare(b.label.toString()))
      } else {
        return [...options]
      }
    }
  }, [options]);

  return (
    <>
      {Object.entries(inputs ?? {}).map(([key, valueOpt], index) => {
        const optSelected = opts?.find(opt => opt.value === valueOpt);

        return <div key={index} className="flex items-center my-2">
          <label className="mb-1" htmlFor="">
            Variable {index + 1}
          </label>
          <ValidateFieldRequired
              value={value}
              required={required}
              disabled={disabled}>
            <>
              <CreatableSelect isMulti={false}
                      classNamePrefix="react-select"
                      placeholder={placeholder? placeholder:`${t('select_placeholder')}`}
                      className={className}
                      id={id}
                      inputValue={inputValue}
                      onInputChange={(e, action: InputActionMeta) => {
                        if (onInputChange) {
                          if (action.action === "input-change") {
                            onInputChange(e, action);
                          }
                        }
                      }}
                      isClearable={isClearable}
                      menuPlacement={menuPlacement}
                      filterOption={filterOption}
                      noOptionsMessage={() => noOptionsMessage ?? "No options"}
                      defaultValue={defaultValue}
                      isLoading={loading}
                      isDisabled={disabled}
                      menuPosition={menuPosition ?? undefined}
                      name={name}
                      value={optSelected}
                      styles={styles}
                      onChange={(value: ValueType<any, any>) => {
                        if (onChange) {
                          if (value.__isNew__) {
                            if (!value.hasOwnProperty('template')) {
                              value.template = inputs;
                            }
                            value.template[""+(index+1)] = value.value;
                          } else {
                            value.template[""+(index+1)] = value.value;
                          }
                          return onChange(value.template);
                        }
                        return null;
                      }}
                      options={opts}
              />
            </>
          </ValidateFieldRequired>
        </div>
      })}
    </>
  );
};

export default MultiInputComponent;
